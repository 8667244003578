import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    color: '#161c1c',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto #161c1c',
        outlineOffset: 2
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
    }
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#000',
    border: '2px solid white',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 40%,transparent 32%)',
        content: '""'
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3'
    }
});

// Inspired by blueprintjs
function BpRadio(props) {
    return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
}

const Choice = ({ alignment, label, choiceItems, defaultChoice }) => {
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                row={alignment === 'row'}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={defaultChoice ? defaultChoice : choiceItems[0]}
                name="radio-buttons-group"
            >
                {choiceItems.map((choiceItem, index) => {
                    return (
                        <FormControlLabel
                            key={`${choiceItem}_${index}`}
                            value={choiceItem}
                            control={<BpRadio />}
                            label={<span style={{ fontFamily: 'Suisse Intl' }}>{choiceItem}</span>}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

Choice.propTypes = {
    alignment: PropTypes.string,
    label: PropTypes.string,
    choiceItems: PropTypes.arrayOf(PropTypes.string),
    defaultChoice: PropTypes.string
};

export default Choice;
