import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SurveyTool from './SurveyTool';
import TranslationTool from './TranslationTool';
import MeasurementTool from './MeasurementTool';
import CameraTool from './CameraTool';
// import LegendTool from './LegendTool';
import RobotTool from './RobotTool';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AnnotationTool from './AnnotationTool';
import LinkTool from './LinkTool';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    })
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: drawerWidth
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
}));

const FilterDrawer = ({ open, activeTool, closeInfo }) => {
    const theme = useTheme();
    const [openWarn, setOpenWarn] = React.useState(false);

    React.useEffect(() => {
        console.log('Drawer state', open);
        if (document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-toolbar')) {
            if (open) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-toolbar').style.transform =
                    'translateX(-304px)';
                document.querySelector('twinworld-app').shadowRoot.querySelector('#profileBox').style.transform = 'translateX(-282px)';
            } else {
                document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-toolbar').style.transform = 'unset';
                document.querySelector('twinworld-app').shadowRoot.querySelector('#profileBox').style.transform = 'translateX(22px)';
            }
        }
        open ? console.log('Opening') : console.log('Closing');
        document.querySelector('twinworld-app').shadowRoot.querySelector('#cesiumContainer').style.width = 'calc(100% - 380px);';
    }, [open]);

    const handleCloseWarn = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenWarn(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // const renderActivetool = () => {
    //     switch (activeTool) {
    //         case 'Info':
    //             return <SurveyTool closeSurvey={closeInfo} />;
    //         case 'Transform':
    //             return <TranslationTool />;
    //         case 'Measurement':
    //             return <MeasurementTool />;
    //         case 'Pov':
    //             return <CameraTool />;
    //         case 'Legend':
    //             return <LegendTool closeLegend={closeInfo} />;
    //         case 'Robot':
    //             return <RobotTool closeRobot={closeInfo} />;
    //         case 'Link':
    //             return <LinkTool />;
    //         case 'Annotation':
    //             return <AnnotationTool />;
    //     }
    // };

    return (
        // <Box
        //     onClick={(event) => {
        //         if (event.target.id !== 'btnOpener' && !document.querySelector('twinworld-app').shadowRoot.querySelector('#rightSideDrawer').contains(event.target)) {
        //             return handleDrawerClose();
        //         }
        //     }}
        // >
        //     <Button id="btnOpener" onClick={handleDrawerOpen}>
        //         Right
        //     </Button>
        //     <Box sx={{ display: 'flex' }}>
        <Drawer
            id="rightSideDrawer"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth
                }
            }}
            PaperProps={{
                sx: {
                    backgroundColor: '#161c1c',
                    color: '#fff',
                    width: '304px !important',
                    zIndex: 2000
                }
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            {renderActivetool()}
        </Drawer>
    );
};

FilterDrawer.propTypes = {
    open: PropTypes.bool,
    activeTool: PropTypes.string,
    closeInfo: PropTypes.func
};

export default FilterDrawer;
