import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isNotifDrawerOpen: false
};

const menu = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setIsNotifDrawerOpen: (state, action) => {
            state.isNotifDrawerOpen = action.payload;
        }
    }
});

export default menu.reducer;

export const { setIsNotifDrawerOpen } = menu.actions;
