import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import visibleImage from 'icons/RightToolbar/Visible.svg';
import Switcher from 'components/Switcher';
import Choice from 'components/Choice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

TabPanel.propTypes = {
    children: PropTypes.string,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: grey[800],
    '&:not(:last-of-type)': {
        borderColor: '#fff !important'
    },
    '&:hover': {
        backgroundColor: grey[700]
    }
}));

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number
};

const RobotTool = ({ closeRobot }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [weather, setWeather] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [scanningState, setScanningState] = React.useState('scanning');

    //const [backdropOpen, setBackdropOpen] = React.useState(!window.scanningTraceInterval || window.scanState === 'scanning');
    const [backdropOpen, setBackdropOpen] = React.useState(true);
    const handleClose = () => {
        clearInterval(window.scanningTraceInterval);
        window.scanningTraceInterval = null;
        setBackdropOpen(false);
        closeRobot();
    };
    const handleToggle = () => {
        setBackdropOpen(!backdropOpen);
    };

    React.useEffect(() => {
        const checkScanState = setInterval(() => {
            if (window.scanState === 'complete') {
                clearInterval(checkScanState);
                setBackdropOpen(false);
                setScanningState('scanComplete');
                if (window.destCenter) {
                    fetch(
                        `https://api.openweathermap.org/data/2.5/weather?lat=${(window.destCenter.latitude * 180) / Cesium.Math.PI}&lon=${
                            (window.destCenter.longitude * 180) / Cesium.Math.PI
                        }&appid=811aec9d921ed18fcb7555a663efa13e`
                    )
                        .then((data) => data.json())
                        .then((dataJson) => {
                            console.log('Weather data', dataJson);
                            setWeather(JSON.stringify(dataJson.main));
                        });
                }
            } else {
                // window.startRobotScanner(200, true, false);
                setScanningState('scanning');
            }
        }, 1000);
    }, [window.scanState]);

    React.useEffect(() => {
        setInterval(() => {
            if (window.iterPosition && window.positions) {
                setProgress((window.iterPosition * 100.0) / window.positions.length);
            }
        }, 2000);
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <Fab
                    aria-label="close"
                    onClick={handleClose}
                    color="gray"
                    className="legendClose"
                    sx={{
                        border: 'none !important',
                        boxShadow: 'none'
                    }}
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                {/* <h1 style={{ fontSize: '20px', marginTop: '5px' }}>Location Intelligence</h1> */}
                {window.scanState === 'scanning' && (
                    <div>
                        {typeof window.originPosition !== 'undefined' && <h2 style={{ fontSize: '15px' }}>Scanning surrounding...</h2>}
                        {typeof window.originPosition === 'undefined' && (
                            <h3 style={{ fontSize: '14px' }}>
                                Search for an area and place a building in it.
                                <br />
                                Location Intelligence will then find places of interest around it.
                            </h3>
                        )}
                        {typeof window.originPosition !== 'undefined' && (!window.iterPosition || !window.positions) && (
                            <div
                                style={{
                                    height: '100px',
                                    width: '100px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    position: 'absolute',
                                    transform: 'translateX(70%) translateY(-170px)',
                                    backgroundColor: '#f0f8ff8a',
                                    borderRadius: '50%',
                                    color: '#1e1d30'
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </div>
                        )}
                        {typeof window.originPosition !== 'undefined' && window.positions && window.iterPosition && (
                            <div
                                style={{
                                    height: '100px',
                                    width: '100px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    position: 'absolute',
                                    transform: 'translateX(70%) translateY(-170px)',
                                    backgroundColor: '#f0f8ff8a',
                                    borderRadius: '50%',
                                    color: '#1e1d30'
                                }}
                            >
                                <div>
                                    <CircularProgressWithLabel value={progress} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {(window.scanState === 'scanning' || window.scanState === 'complete') && (
                    <Box sx={{ padding: '0', height: '390px', overflow: 'hidden' }}>
                        <AppBar position="static" sx={{ backgroundColor: 'transparent', color: '#fff', boxShadow: 'none' }}>
                            {/* <h2 style={{ fontSize: '15px' }}>Location Intelligence scanner complete at user level</h2> */}
                        </AppBar>
                        <Fab aria-label="close" onClick={handleClose} color="gray" className="surveyClose">
                            <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                        </Fab>
                        <h3 style={{ fontSize: '15px' }}>Calculation is as follows :</h3>
                        <div
                            style={{
                                height: '340px',
                                width: '330px',
                                overflow: 'auto'
                            }}
                        >
                            {window.sheetList}
                            {(window.scanState === 'scanning' || window.scanState === 'complete') &&
                                window.sheetList &&
                                window.sheetList.length > 0 &&
                                window.sheetList.map((entityDetail, index) => {
                                    return (
                                        <div className="tabularData" key={`${Math.floor(Math.random() * 1000)}_${index}`}>
                                            <span
                                                style={{
                                                    // backgroundColor: '#57a0e8',
                                                    // color: '#fff',
                                                    fontWeight: 'bold',
                                                    padding: '4px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                {` ( `}
                                                {index + 1}
                                                {` ) `}
                                            </span>
                                            {entityDetail}
                                        </div>
                                    );
                                })}
                            {(window.scanState === 'scanning' || window.scanState === 'complete') &&
                                (window.sheetList !== undefined || window.sheetList !== null) &&
                                window.sheetList instanceof HTMLElement && <div className="tabularData">{window.sheetList}</div>}
                            {window.scanState === 'complete' &&
                                (window.sheetList === undefined || window.sheetList === null || window.sheetList.length === 0) && (
                                    <div className="tabularData">No data found</div>
                                )}
                        </div>
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

RobotTool.propTypes = {
    closeRobot: PropTypes.func,
    value: PropTypes.number
};
export default RobotTool;
