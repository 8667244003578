// project import
import AssetLibrary from './AssetLibrary';
import SimpleBar from 'components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
    <SimpleBar
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
            },
            '& .simplebar-offset': {
                marginLeft: '-3px'
            },
            overflow: 'hidden'
        }}
    >
        <AssetLibrary />
    </SimpleBar>
);

export default DrawerContent;
