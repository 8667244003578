import { lazy } from 'react';

// project import
import ProtectedRoute from 'auth/ProtectedRoute';
import TwinupWorldLayout from 'layout/TwinupWorldLayout';

// ==============================|| MAIN ROUTING ||============================== //

const CallbackRoutes = {
    path: '/twinup-world',
    element: (
        <ProtectedRoute>
            <TwinupWorldLayout />
        </ProtectedRoute>
    )
};

export default CallbackRoutes;
