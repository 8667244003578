import * as React from 'react';
import PropTypes from 'prop-types';

const TranslationTool = ({ assetInfo }) => {
    return <div>Translation Tool</div>;
};

TranslationTool.propTypes = {
    assetInfo: PropTypes.object
};
export default TranslationTool;
