// ==============================|| THEME CONFIG  ||============================== //

const config = {
    defaultPath: '/',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 60;
export const leftToolbarWidth = 64;
