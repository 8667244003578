import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import visibleImage from 'icons/RightToolbar/Visible.svg';
import Switcher from 'components/Switcher';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const MeasurementTool = ({ assetInfo, closeSurvey }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { background: '#fff', color: '#fff' } }}
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="measurement tool tabs"
                    >
                        <Tab
                            sx={{
                                color: '#fff',
                                fontFamily: 'Suisse Intl',
                                fontSize: '14px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold'
                            }}
                            label="Selection"
                            {...a11yProps(0)}
                        />
                        {/* <Tab
                            sx={{ color: '#fff', fontFamily: 'Suisse Intl', fontSize: '14px', textTransform: 'uppercase', fontWeight: 'bold' }}
                            label="Saved Measurements"
                            {...a11yProps(1)}
                        /> */}
                    </Tabs>
                </AppBar>
                <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <div>
                                <div className="filterToolLabel">Measure mode</div>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="pickObj"
                                    name="radio-buttons-group"
                                >
                                    {/* <FormControlLabel value="measureLine" control={<Radio />} label="Measure Line/area/volume" /> */}
                                    <FormControlLabel value="pickObj" control={<Radio />} label="Pick object to measure" />
                                </RadioGroup>
                            </div>
                            {/* <div>
                                <div className="filterToolLabel">Units</div>
                                <Switcher label="Imperial" leftLabel="Metric" id="metricSystem" />
                            </div> */}
                            <div>
                                <div className="filterToolLabel">Current measurement</div>
                                <Grid container spacing={1}>
                                    {/* <Grid item xs={12} sm={6}>
                                        <div className="clickedPointLabel">Circumference:</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointText">{window.pickedAssetDetails.latitude}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointLabel">Area:</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointText">{window.pickedAssetDetails.longitude}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointLabel">Volume:</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointText">{window.pickedAssetDetails.height}</div>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointLabel">Distance:</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointText">{window.distanceBetween}</div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <div className="filterToolLabel">Visibility</div>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={1}>
                                        <div className="clickedPointLabel">
                                            <img src={visibleImage} alt="Visible entity" width="20" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="clickedPointText">Visible</div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        Item Two
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </React.Fragment>
    );
};

MeasurementTool.propTypes = {
    assetInfo: PropTypes.object,
    closeSurvey: PropTypes.func
};
export default MeasurementTool;
