import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const ItemTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}));

const AssetItem = ({ item, level, bottomFooter }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const [currActiveItem, setCurrActiveItem] = useState('search');

    const { drawerOpen, openItem } = menu;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    // let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    let listItemProps = {
        component: forwardRef((props, ref) => (
            <span style={{ height: '6.5vh', maxHeight: '55px' }} ref={ref} {...props} to={item.url} target={itemTarget} />
        ))
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        setCurrActiveItem(id);
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? (
        item.iconType === 'png' ? (
            item.icon
        ) : (
            <Icon
                className="svg-icon-span"
                style={{ fontSize: drawerOpen ? '30px' : '1.25rem', transform: 'translateX(-8px)', margin: '5px 0' }}
            />
        )
    ) : (
        false
    );

    const ActiveIcon = item.activeIcon;
    const showTooltip = item.showTooltip;
    const activeItemIcon = (
        <ActiveIcon
            className="svg-icon-span"
            style={{ fontSize: drawerOpen ? '30px' : '1.25rem', transform: 'translateX(-8px)', margin: '5px 0' }}
        />
    );

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;
    // active menu item on page load
    useEffect(() => {
        if (document.location.pathname) {
            const currentIndex = document.location.pathname
                .toString()
                .split('/')
                .findIndex((id) => id === item.id);
            if (currentIndex > -1) {
                dispatch(activeItem({ openItem: [item.id] }));
            }
        }
        // eslint-disable-next-line
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    return (
        <span>
            {showTooltip && (
                <ItemTooltip
                    TransitionComponent={Zoom}
                    placement="right"
                    sx={{
                        width: '200px'
                    }}
                    title={showTooltip}
                >
                    <ListItemButton
                        {...listItemProps}
                        disabled={item.disabled}
                        onClick={() => itemHandler(item.id)}
                        selected={isSelected}
                        id={item.id}
                        // title={item.id?.replace(/^[a-z]/, (l) => l.toUpperCase())}
                        sx={{
                            zIndex: 1201,
                            height: '6.5vh',
                            maxHeight: '55px',
                            marginTop: '1vh',
                            marginBottom: '1vh',
                            pl: drawerOpen ? `${level * 28}px` : 1.5,
                            py: !drawerOpen && level === 1 ? 1.25 : 1,
                            ...(drawerOpen && {
                                '&:hover': {
                                    bgcolor: '#5E667A'
                                },
                                '&.Mui-selected': {
                                    bgcolor: '#5E667A',
                                    borderRight: `2px solid ${theme.palette.primary.main}`,
                                    color: iconSelectedColor,
                                    '&:hover': {
                                        color: iconSelectedColor,
                                        bgcolor: '#5E667A'
                                    }
                                }
                            }),
                            ...(!drawerOpen && {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                '&.Mui-selected': {
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    },
                                    bgcolor: 'transparent'
                                }
                            }),
                            ...(bottomFooter && {
                                position: 'absolute',
                                bottom: '0'
                            })
                        }}
                    >
                        {itemIcon && (
                            <ListItemIcon
                                id={`${item.id}-asset`}
                                sx={{
                                    minWidth: 28,
                                    color: isSelected ? iconSelectedColor : textColor,
                                    ...(!drawerOpen && {
                                        borderRadius: 1.5,
                                        width: 36,
                                        height: 36,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            bgcolor: 'secondary.lighter'
                                        }
                                    }),
                                    ...(!drawerOpen &&
                                        isSelected && {
                                            bgcolor: 'primary.lighter',
                                            '&:hover': {
                                                bgcolor: 'primary.lighter'
                                            }
                                        })
                                }}
                            >
                                {window.activeOption === item.id ? activeItemIcon : itemIcon}
                            </ListItemIcon>
                        )}
                        {(drawerOpen || (!drawerOpen && level !== 1)) && (
                            <ListItemText
                                primary={
                                    <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                                        {item.title}
                                    </Typography>
                                }
                            />
                        )}
                        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                </ItemTooltip>
            )}
            {!showTooltip && (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    onClick={() => itemHandler(item.id)}
                    selected={isSelected}
                    id={item.id}
                    // title={item.id?.replace(/^[a-z]/, (l) => l.toUpperCase())}
                    sx={{
                        zIndex: 1201,
                        height: '6.5vh',
                        maxHeight: '55px',
                        marginTop: '1vh',
                        marginBottom: '1vh',
                        pl: drawerOpen ? `${level * 28}px` : 1.5,
                        py: !drawerOpen && level === 1 ? 1.25 : 1,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: '#5E667A'
                            },
                            '&.Mui-selected': {
                                bgcolor: '#5E667A',
                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: '#5E667A'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        }),
                        ...(bottomFooter && {
                            position: 'absolute',
                            bottom: '0'
                        })
                    }}
                >
                    {itemIcon && (
                        <ListItemIcon
                            id={`${item.id}-asset`}
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        bgcolor: 'secondary.lighter'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'primary.lighter',
                                        '&:hover': {
                                            bgcolor: 'primary.lighter'
                                        }
                                    })
                            }}
                        >
                            {window.activeOption === item.id ? activeItemIcon : itemIcon}
                        </ListItemIcon>
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                                    {item.title}
                                </Typography>
                            }
                        />
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </span>
    );
};

AssetItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    bottomFooter: PropTypes.bool
};

export default AssetItem;
