import * as React from 'react';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Scenes from './Scenes';
import Tasks from './Tasks';

const options = ['Tasks', 'Scenes'];
const sceneOptions = ['Completed', 'All Scenes', 'Drafts'];

function SceneTaskExplorer(props) {
    const { modelUploaded, modelUploadingInProcess } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sceneAnchorEl, setSceneAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedSceneIndex, setSelectedSceneIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const sceneOpen = Boolean(sceneAnchorEl);
    const handleSceneClickListItem = (event) => {
        setSceneAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        window.switchSceneTask = function (sceneOrTask) {
            if (sceneOrTask === 'scene') {
                setSelectedIndex(0);
                document.querySelector('#folderWrapper').style.display = 'block';
                document.querySelector('#folderWrapper').style.top = '10px';
                document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.transform =
                    'translateX(12px) translateY(24px)';
            } else {
                setSelectedIndex(1);
                document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.transform =
                    'translateX(12px) translateY(-24px)';
            }
        };
        if (window.location.pathname.indexOf('twinworld') >= 0) {
            setSelectedIndex(1);
        }
    }, []);

    const handleMenuItemClick = (event, index) => {
        if (index === 1) {
            document.querySelector('#folderWrapper').style.display = 'block';
            document.querySelector('#folderWrapper').style.top = '10px';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.transform =
                'translateX(12px) translateY(24px)';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#navigationDiv').style.transform = 'translateY(-59px)';
        } else {
            document.querySelector('#folderWrapper').style.display = 'none';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.transform =
                'translateX(12px) translateY(-24px)';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#navigationDiv').style.transform = 'unset';
        }
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleSceneMenuItemClick = (event, index) => {
        setSelectedSceneIndex(index);
        setSceneAnchorEl(null);
        document.querySelector('twinworld-app').setAttribute('scene_type', sceneOptions[index]);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSceneListClose = () => {
        setSceneAnchorEl(null);
    };

    return (
        <div style={{ display: 'none' }}>
            <List
                component="nav"
                aria-label="Device settings"
                sx={{
                    bgcolor: '#363c4f',
                    height: '25px',
                    zIndex: 1000,

                    marginTop: '-5px',
                    position: 'absolute',
                    right: '7px',
                    paddingBottom: 0,
                    zIndex: 1001
                }}
            >
                <ListItem
                    button
                    sx={{ padding: 0, maxHeight: '20px', margin: 0, textAlign: 'right' }}
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={
                            <div>
                                <span style={{ fontSize: '13px', color: '#fff', cursor: 'pointer' }}>{options[selectedIndex]} </span>
                                <img
                                    style={{ width: '10px', transform: 'translateY(2.5px)', cursor: 'pointer' }}
                                    alt="sceneTask"
                                    src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/sceneSwitcher2.png"
                                />
                            </div>
                        }
                    />
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            {selectedIndex === 1 && (
                <div
                    style={{
                        backgroundColor: '#363c4f',
                        textAlign: 'right',
                        padding: '1px 5px',
                        paddingBottom: '10px',
                        paddingTop: '20px'
                    }}
                >
                    <List
                        component="nav"
                        aria-label="Device Scene settings"
                        sx={{
                            bgcolor: '#363c4f',
                            height: '25px',
                            zIndex: 1000,

                            marginTop: '10px',
                            transform: 'translateY(100px)',
                            position: 'absolute',
                            right: '8px',
                            zIndex: 1002
                        }}
                    >
                        <ListItem
                            button
                            id="lock-button-scenes"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-label="when device is locked"
                            aria-expanded={sceneOpen ? 'true' : undefined}
                            onClick={handleSceneClickListItem}
                        >
                            <ListItemText
                                primary={
                                    <div>
                                        <span style={{ fontSize: '13px', color: '#fff', cursor: 'pointer' }}>
                                            {sceneOptions[selectedSceneIndex]}{' '}
                                        </span>
                                        <img
                                            style={{ width: '10px', transform: 'translateY(2.5px)', cursor: 'pointer' }}
                                            alt="sceneTask"
                                            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/sceneSwitcher2.png"
                                        />
                                    </div>
                                }
                            />
                        </ListItem>
                    </List>
                    <Menu
                        id="lock-menu-scenes"
                        className="sceneMenu"
                        anchorEl={sceneAnchorEl}
                        open={sceneOpen}
                        sx={{
                            left: 'unset',
                            right: '30px',
                            width: '150px'
                        }}
                        onClose={handleSceneListClose}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button-scenes',
                            role: 'listbox'
                        }}
                    >
                        {sceneOptions.map((option, index) => (
                            <MenuItem
                                key={option}
                                selected={index === selectedSceneIndex}
                                onClick={(event) => handleSceneMenuItemClick(event, index)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {selectedIndex === 1 && <Scenes />}
            {selectedIndex === 0 && <Tasks modelUploaded={modelUploaded} modelUploadingInProcess={modelUploadingInProcess} />}

            <div
                id="snapshots"
                style={{
                    width: '40%',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    height: '20px',
                    position: 'absolute',
                    transform: 'translate3d(500px, -50px, 10px)',
                    zIndex: '1000'
                }}
            ></div>
        </div>
    );
}

SceneTaskExplorer.propTypes = {
    modelUploaded: PropTypes.bool,
    modelUploadingInProcess: PropTypes.bool
};

export default SceneTaskExplorer;
