import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import reactToWebComponent from 'convert-react-to-web-component';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
// import App from './App';
import TwinworldApp from './TwinworldApp';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
/*
const container = document.querySelector('twinworld-app').shadowRoot.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    // <StrictMode>
    <ReduxProvider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ReduxProvider>
    // </StrictMode>
);
*/
const middleware = (value) => (value.includes('{') ? undefined : value);
reactToWebComponent(TwinworldApp, { attributes: ['scene_id', 'scene_folder'], shadowDom: 'open', middleware: middleware });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ==============================|| MAIN - REGISTER SERVICE WORKER  ||============================== //

/*
if ('serviceWorker' in navigator) {
    serviceWorker.register();
}
*/
