import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import ColorPicker from 'mui-color-picker';
import CloseIcon from '@mui/icons-material/Close';

const LegendTool = ({ assetInfo, closeLegend }) => {
    const [checkedBuildingTypes, setCheckedBuildingTypes] = React.useState(new Set());

    const [colors, setColors] = React.useState({
        apartment: '#03c7cd',
        hospital: '#ff7376',
        commercial: 'rgb(192 199 3)',
        school: '#11700c'
    });
    const [apartmentColor, setApartmentColor] = React.useState('#03c7cd');

    const fixWorldColors = () => {
        window.colorConditions = [];
        if (checkedBuildingTypes.has('apartments')) {
            colorConditions.push([
                "${feature['building']} === 'apartments' || ${feature['building']} === 'residential' || ${feature['building']} === 'house'",
                `color('${colors.apartment}')`
            ]);
        }
        if (checkedBuildingTypes.has('hospitals')) {
            colorConditions.push(["${feature['building']} === 'hospital'", `color('${colors.hospital}')`]);
        }
        if (checkedBuildingTypes.has('offices')) {
            colorConditions.push(["${feature['building']} === 'commercial'", `color('${colors.commercial}')`]);
        }
        if (checkedBuildingTypes.has('schools')) {
            colorConditions.push(["${feature['building']} === 'school'", `color('${colors.school}')`]);
        }
        buildingsTileset.style =
            window.hideBuildingsStr && window.hideBuildingsStr !== ''
                ? new Cesium.Cesium3DTileStyle({
                      color: {
                          conditions: colorConditions
                      },
                      show: window.hideBuildingsStr
                  })
                : new Cesium.Cesium3DTileStyle({
                      color: {
                          conditions: colorConditions
                      }
                  });

        /*
        buildingsTileset.style = new Cesium.Cesium3DTileStyle({
            color: {
                conditions: [
                    ["${feature['building']} === 'apartments' || ${feature['building']} === 'residential'", "color('cyan')"],
                    ["${feature['building']} === 'hospital'", "color('rgb(255, 115, 118)')"],
                    ["${feature['building']} === 'commercial'", "color('yellow')"],
                    ["${feature['building']} === 'school'", "color('green')"],
                    [true, "color('white')"]
                ]
            }
        });
        */
    };

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <h1 style={{ fontSize: '24px' }}>Legend</h1>
                <Fab
                    aria-label="close"
                    onClick={closeLegend}
                    color="gray"
                    className="legendClose"
                    sx={{
                        border: 'none !important',
                        boxShadow: 'none'
                    }}
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <Checkbox
                                id="showApartments"
                                sx={{ marginTop: '-7px' }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        checkedBuildingTypes.add('apartments');
                                    } else {
                                        checkedBuildingTypes.delete('apartments');
                                    }
                                    fixWorldColors();
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid className="colorPicker" item xs={12} sm={6}>
                            <div className="clickedPointLabel">
                                <ColorPicker
                                    sx={{ backgroundColor: colors.apartment }}
                                    name="color"
                                    defaultValue={colors.apartment}
                                    onChange={(color) => {
                                        if (color) {
                                            setColors((colors) => ({
                                                ...colors,
                                                ...{ apartment: color }
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="clickedPointText">Apartments</div>
                        </Grid>
                        <Grid className="colorPicker" item xs={12} sm={2}>
                            <Checkbox
                                id="showHospitals"
                                sx={{ marginTop: '-7px' }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        checkedBuildingTypes.add('hospitals');
                                    } else {
                                        checkedBuildingTypes.delete('hospitals');
                                    }
                                    fixWorldColors();
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid className="colorPicker" item xs={12} sm={6}>
                            <div className="clickedPointLabel">
                                <ColorPicker
                                    sx={{ backgroundColor: colors.hospital }}
                                    defaultValue={colors.hospital}
                                    onChange={(color) => {
                                        if (color) {
                                            setColors((colors) => ({
                                                ...colors,
                                                ...{ hospital: color }
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="clickedPointText">Hospitals</div>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Checkbox
                                id="showCommercialBuildings"
                                sx={{ marginTop: '-7px' }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        checkedBuildingTypes.add('offices');
                                    } else {
                                        checkedBuildingTypes.delete('offices');
                                    }
                                    fixWorldColors();
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid className="colorPicker" item xs={12} sm={6}>
                            <div className="clickedPointLabel">
                                <ColorPicker
                                    sx={{ backgroundColor: colors.commercial }}
                                    defaultValue={colors.commercial}
                                    onChange={(color) => {
                                        if (color) {
                                            setColors((colors) => ({
                                                ...colors,
                                                ...{ commercial: color }
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="clickedPointText">Commercial</div>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Checkbox
                                sx={{ marginTop: '-7px' }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        checkedBuildingTypes.add('schools');
                                    } else {
                                        checkedBuildingTypes.delete('schools');
                                    }
                                    fixWorldColors();
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid className="colorPicker" item xs={12} sm={6}>
                            <div className="clickedPointLabel">
                                <ColorPicker
                                    sx={{ backgroundColor: colors.school }}
                                    defaultValue={colors.school}
                                    onChange={(color) => {
                                        if (color) {
                                            setColors((colors) => ({
                                                ...colors,
                                                ...{ school: color }
                                            }));
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="clickedPointText">Schools</div>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

LegendTool.propTypes = {
    assetInfo: PropTypes.object,
    closeLegend: PropTypes.func
};
export default LegendTool;
