import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Box, List, Typography, ListItemIcon } from '@mui/material';
import { LineOutlined } from '@ant-design/icons';

// project import
import AssetItem from './AssetItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const AssetGroup = ({ item }) => {
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;

    const navCollapse = item.children?.map((menuItem) => {
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
                        collapse - only available in paid version
                    </Typography>
                );
            case 'item':
                return <AssetItem key={menuItem.id} item={menuItem} level={1} />;
            case 'spacer':
                return <div key={menuItem.id} style={{ height: `${menuItem.space}vh` }} />;
            case 'divider':
                return (
                    <ListItemIcon key={menuItem.id}>
                        <LineOutlined style={{ width: '65px', transform: 'scaleX(3)' }} />
                    </ListItemIcon>
                );
            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                );
        }
    });

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{
                overflowX: 'hidden',
                overflowY: 'auto',
                mb: drawerOpen ? 1.5 : 0,
                py: 0,
                zIndex: 0,
                marginTop: '134px',
                height: 'calc(100vh - 162px)',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
        >
            {navCollapse}
        </List>
    );
};

AssetGroup.propTypes = {
    item: PropTypes.object
};

export default AssetGroup;
