import { useCallback, useRef, useState } from 'react';
import { Avatar, Box, Button, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Typography } from '@mui/material';
import Transitions from 'components/@extended/Transitions';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import UserDialog from 'components/UserDialog';
import { useSelector } from 'react-redux';

const User = () => {
    // const user = useAppSelector((state) => state.user.me);
    // const profileImage = user?.profile_image?.s3_url;
    const user = useSelector((state) => state?.user?.me);
    const auth = useSelector((state) => state?.auth?.value);

    const profileImage = user?.profile_image?.s3_url;

    const handleLogout = async () => {
        auth.logout();
    };

    const anchorRef = useRef(null);
    const [popperOpen, setPopperOpen] = useState(false);
    const handlePopperToggle = useCallback(() => {
        setPopperOpen((prevOpen) => !prevOpen);
    }, []);

    const handlePopperClose = (event) => {
        // @ts-ignore
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setPopperOpen(false);
    };
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalToggle = useCallback(() => {
        setModalOpen((prev) => !prev);
    }, []);

    return (
        <>
            <UserDialog open={modalOpen} onClose={handleModalToggle} />
            <Box>
                <ButtonBase ref={anchorRef} onClick={handlePopperToggle}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                        <Avatar alt="profile user" src={profileImage} sx={{ width: 32, height: 32 }} />
                        <Typography variant="subtitle1">{user && user['cognito:username']}</Typography>
                    </Stack>
                </ButtonBase>
                <Popper
                    placement="bottom-end"
                    open={popperOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 10]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={popperOpen} {...TransitionProps}>
                            {popperOpen && (
                                <Paper>
                                    <ClickAwayListener onClickAway={handlePopperClose}>
                                        <CardContent>
                                            <Grid container>
                                                <Grid item>
                                                    <Button startIcon={<EditOutlined />} onClick={handleModalToggle}>
                                                        Update Profile
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button startIcon={<LogoutOutlined />} onClick={handleLogout}>
                                                        Logout
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </ClickAwayListener>
                                </Paper>
                            )}
                        </Transitions>
                    )}
                </Popper>
            </Box>
        </>
    );
};

export default User;
