import { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, ButtonGroup, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import TerrainLayout from 'layout/TerrainLayout';
import AssessSite from 'layout/AssessSite';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import Basic2d3dSwitch from 'components/Basic2d3dSwitch';

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ scene_id, scene_folder }) => {
    // const auth = useSelector((state) => state.auth.value);
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.querySelector('twinworld-app').shadowRoot.querySelector('#navChanger').click();
        window.showAlert = (alertMessage) => {
            setAlert({
                open: true,
                message: alertMessage
            });
        };
    }, [open, drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            {/* <Header open={open} sx={{ zIndex: '1400', backgroundColor: 'green' }} handleDrawerToggle={handleDrawerToggle} /> */}
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 }, paddingRight: '0 !important', paddingLeft: '0 !important' }}
            >
                <Toolbar />
                {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
                {/* <Outlet /> */}
                <TerrainLayout scene_id={scene_id} scene_folder={scene_folder} />
                <div id="notifier"></div>

                {/* <AssessSite /> */}
            </Box>
        </Box>
    );
};

MainLayout.propTypes = {
    scene_id: PropTypes.string,
    scene_folder: PropTypes.string
};

export default MainLayout;
