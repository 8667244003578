import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

const AuthCallback = Loadable(lazy(() => import('auth/AuthCallback')));

// ==============================|| MAIN ROUTING ||============================== //

const CallbackRoutes = {
    path: '/callback',
    element: <AuthCallback />
};

export default CallbackRoutes;
