import { InputLabel, TextField } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

const InputRow = ({ field }) => {
    return (
        <>
            <InputLabel htmlFor={field}>{field}</InputLabel>
            <Field name={field} id={field} as={TextField} />
            <ErrorMessage name={field} component="span" className="error" />
        </>
    );
};

InputRow.propTypes = {
    field: PropTypes.string
};

export default InputRow;
