import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

const StyledSwitcher = styled(Switch)(({ theme }) => ({
    padding: 8,
    fontFamily: 'Suisse Intl',
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: '#fff !important',
        border: '1px solid #161c1c',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&:before': {
            left: 12
        },
        '&:after': {
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        backgroundColor: '#000',
        width: 16,
        height: 16,
        margin: 2
    }
}));

const Switcher = ({ label, leftLabel, id }) => {
    return (
        <FormGroup>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {leftLabel && (
                    <label style={{ marginRight: '12px', marginTop: '9px' }} htmlFor={id}>
                        {leftLabel}
                    </label>
                )}
                <FormControlLabel
                    style={{ fontFamily: 'Suisse Intl' }}
                    id={id}
                    control={<StyledSwitcher defaultChecked />}
                    label={<span style={{ fontFamily: 'Suisse Intl' }}>{label}</span>}
                />
            </Box>
        </FormGroup>
    );
};

Switcher.propTypes = {
    label: PropTypes.string.isRequired,
    leftLabel: PropTypes.string,
    id: PropTypes.string
};

export default Switcher;
