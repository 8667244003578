import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';

const config = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getUser = createAsyncThunk(`user/get`, async () => {
    const response = await axios.get(`/user/`);
    return response.data;
});

export const updateUser = createAsyncThunk(`user/update`, async (data) => {
    const response = await axios.patch(`/user/`, data);
    return response.data;
});

export const updateUserImages = createAsyncThunk(`user/image`, async (args) => {
    const { type, data } = args;
    const formData = new FormData();
    formData.append('upload_file', data);
    const response = await axios.patch(`/user/image/${type}`, formData, config);
    return response.data;
});

export const followUser = createAsyncThunk(`user/follow`, async (args) => {
    const { userId } = args;
    const response = await axios.patch(`/user/follow/${userId}`);
    return response.data;
});

export const unfollowUser = createAsyncThunk(`user/unfollow`, async (args) => {
    const { userId } = args;
    const response = await axios.patch(`/user/unfollow/${userId}`);
    return response.data;
});

export const acknowledgeNotification = createAsyncThunk(`user/notification/acknowledge`, async (args) => {
    const { notificationId } = args;
    const response = await axios.patch(`/user/notification/${notificationId}/acknowledge`);
    return response.data;
});

const initialState = {
    obj: {
        profile_image: {
            s3_url: ''
        },
        user_name: '',
        notifications: []
    }
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                getUser.fulfilled,
                updateUserImages.fulfilled,
                updateUser.fulfilled,
                followUser.fulfilled,
                unfollowUser.fulfilled,
                acknowledgeNotification.fulfilled
            ),
            (state, action) => {
                state.obj = action.payload;
            }
        );
    }
});

export default user.reducer;
