import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = SwipeableViews;
const items = [
    <div style={{ display: 'grid', placeItems: 'center', width: '60px' }} className="item" data-value="1">
        <img
            style={{ border: '2px solid grey', borderRadius: '10px', width: '60px' }}
            alt="SF"
            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/Scene1.png"
        />
    </div>,
    <div style={{ display: 'grid', placeItems: 'center', width: '60px' }} className="item" data-value="2">
        <img
            style={{ border: '2px solid grey', borderRadius: '10px', width: '60px' }}
            alt="Bird"
            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/Scene2.png"
        />
    </div>,
    <div style={{ display: 'grid', placeItems: 'center', width: '60px' }} className="item" data-value="3">
        <img
            style={{ border: '2px solid grey', borderRadius: '10px', width: '60px' }}
            alt="Bali"
            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/Scene3.png"
        />
    </div>,
    <div style={{ display: 'grid', placeItems: 'center', width: '60px' }} className="item" data-value="4">
        <img
            style={{ border: '2px solid grey', borderRadius: '10px', width: '60px' }}
            alt="Serbia"
            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/Scene4.png"
        />
    </div>,
    <button
        style={{ display: 'grid', placeItems: 'center', width: '60px', border: 'none', backgroundColor: '#363c50' }}
        className="item"
        data-value="5"
        onClick={() => window.addScene()}
    >
        <img
            style={{ border: '2px solid grey', borderRadius: '10px', width: '75px' }}
            alt="Serbia"
            src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/NewScene.png"
        />
    </button>
];

const responsive = {
    0: { items: 1 },
    268: { items: 3 },
    1024: { items: 3 }
};

function SwipeableScenes() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
            <div style={{ position: 'absolute', zIndex: '1001', color: '#fff', left: '30px' }}>
                Scenes
                <img
                    style={{ width: '5px' }}
                    alt="sceneSwitcher"
                    src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/sceneSwitcher.png"
                />
            </div>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls="true"
                renderPrevButton={() => {
                    return (
                        <p style={{ position: 'absolute', left: '10px', top: '14px', left: '48px' }} className="p-4 absolute left-0 top-0">
                            <img alt="left" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/LeftArrow.png" />
                        </p>
                    );
                }}
                renderNextButton={() => {
                    return (
                        <p style={{ position: 'absolute', right: '10px', top: '0' }} className="p-4 absolute right-0 top-0">
                            {/* <img alt="right" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/RightArrow.png" /> */}
                        </p>
                    );
                }}
            />
        </Box>
    );
}

export default SwipeableScenes;
