import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button } from '@mui/material';
import axios from 'axios';
import { Box, Stack } from '@mui/material';
import { Bars } from 'react-loader-spinner';
import Header from '../../layout/TwinupWorldLayout/Header';
import { openDrawer } from 'store/reducers/menu';

const Login = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loggingIn, setLoggingIn] = useState(false);
    axios.defaults.withCredentials = true;
    const login = useCallback(() => {
        setLoggingIn(true);
        axios
            .post(`./user/login`, {
                email_or_username: userId,
                password: password //'12345abcdeF@'
            })
            .then((resp) => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.detail) {
                    const parts = error.response.data.detail.split(':');
                    setError(parts.length === 2 ? parts[1].trim() : error.response.data.detail);
                } else {
                    setError('Error logging in');
                }
                setLoggingIn(false);
            });
    }, [userId, password, setError]);

    const register = useCallback(() => {
        console.log(userId, password);
    }, [userId, password]);
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            {/* <Header open={open} sx={{ zIndex: '1400', backgroundColor: 'green' }} handleDrawerToggle={handleDrawerToggle} /> */}
            <div
                style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${require('../../images/image-of-day.png')})`
                }}
            >
                <div
                    style={{
                        borderRadius: 16,
                        backgroundColor: 'white',
                        position: 'fixed',
                        width: `min(400px, 80vw)`,
                        height: 500,
                        right: `min(60px, 5vw)`,
                        top: `calc((100% - 500px) / 2)`
                    }}
                >
                    <center>
                        <img
                            alt={'logo'}
                            src={require('../../images/logo-twinmaster.svg')}
                            width={80}
                            height={80}
                            style={{ marginTop: 30 }}
                        />
                        <div style={{ fontSize: 24, fontWeight: 'bold', fontFamily: "Lato,'Helvetica Neue'" }}>Login to TwinMaster</div>
                    </center>
                    <div style={{ margin: 30 }}>
                        <hr
                            style={{
                                height: 2,
                                border: 'none',
                                backgroundColor: 'lightgray',
                                marginBottom: 15
                            }}
                        />

                        <TextField
                            onChange={(e) => setUserId(e.target.value)}
                            value={userId}
                            label={'email or username'}
                            style={{ width: '100%', marginBottom: 10 }}
                        />
                        <TextField
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            label={'password'}
                            type="password"
                            style={{ width: '100%', marginBottom: 10 }}
                        />
                        {loggingIn && <Bars height="40" width="100%" color="#F36F4B" ariaLabel="bars-loading" visible={true} />}
                        {!loggingIn && (
                            <Button
                                disabled={password.length === 0 || userId.length === 0}
                                onClick={login}
                                variant="outlined"
                                style={{
                                    backgroundColor: '#F36F4B',
                                    border: 'none',
                                    color: 'white',
                                    width: '100%',
                                    marginBottom: 10,
                                    height: 40,
                                    borderRadius: 10
                                }}
                            >
                                Login
                            </Button>
                        )}
                        <div
                            style={{
                                width: ' 100%',
                                height: 2,
                                textAlign: 'center',
                                border: 'none',
                                backgroundColor: 'lightgray',
                                lineHeight: '0.1em',
                                margin: '10px 0 20px'
                            }}
                        >
                            <span
                                style={{
                                    background: '#fff',
                                    padding: '0 10px'
                                }}
                            >
                                or
                            </span>
                        </div>
                        <Button
                            onClick={register}
                            variant="outlined"
                            style={{
                                borderColor: 'black',
                                color: 'black',
                                width: '100%',
                                marginBottom: 10,
                                height: 40,
                                borderRadius: 10
                            }}
                        >
                            Create an account
                        </Button>
                        <center>
                            {error && (
                                <div
                                    style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        fontSize: 14
                                    }}
                                >
                                    {error}
                                </div>
                            )}
                        </center>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default Login;
