import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

const autocompleteService = { current: null };

const SearchTool = ({ assetInfo, closeSearch }) => {
    const [checkedBuildingTypes, setCheckedBuildingTypes] = React.useState(new Set());
    const [actionValue, setActionValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        []
    );

    React.useEffect(() => {
        let active = true;

        autocompleteService.current = {
            getPlacePredictions: (request, callback) => {
                console.log('Search string', request);
                if (request.input.toLowerCase().startsWith('place asset at')) {
                    const coordinatesStr = request.input.toLowerCase().replace('place asset at', '').split(',');
                    if (coordinatesStr.length > 2) {
                        const coordinates = coordinatesStr.map((coordinate) => parseFloat(coordinate));
                        const transformPosition = new Cesium.Cartesian3(coordinates[0], coordinates[1], coordinates[2]);
                        transformToPosition(transformPosition);
                    }
                } else if (request.input.toLowerCase().startsWith('scale up asset by')) {
                    const scalingFactStr = request.input.toLowerCase().replace('scale up asset by', '');
                    const scalingFact = parseFloat(scalingFactStr);
                    if (scalingFact > 1 && scalingFact < 2) {
                        window.drone.model.scale = scalingFact;
                    }
                } else if (request.input.toLowerCase().startsWith('scale down asset by')) {
                    const scalingFactStr = request.input.toLowerCase().replace('scale down asset by', '');
                    const scalingFact = parseFloat(scalingFactStr);
                    if (scalingFact > 0 && scalingFact < 1) {
                        window.drone.model.scale = scalingFact;
                    }
                } else if (request.input.toLowerCase().indexOf('take a snapshot') > -1) {
                    window.captureScreen();
                }
                if (request.input.toLowerCase().startsWith('place')) {
                    setTimeout(() => {
                        callback([
                            { label: 'Place asset at', description: 'Place asset at', text: 'Place asset at ' },
                            { label: 'Place asset near', description: 'Place asset near', text: 'Place asset near' }
                        ]);
                    }, 1000);
                } else if (request.input.toLowerCase().startsWith('orient')) {
                    setTimeout(() => {
                        callback([{ label: 'Orient asset by', description: 'Orient asset by', text: 'Orient asset by' }]);
                    }, 1000);
                } else if (request.input.toLowerCase().startsWith('scale')) {
                    setTimeout(() => {
                        callback([
                            { label: 'Scale up asset by', description: 'Scale up asset by', text: 'Scale up asset by' },
                            { label: 'Scale down asset by', description: 'Scale down asset by', text: 'Scale down asset by' }
                        ]);
                    }, 1000);
                } else if (request.input.toLowerCase().indexOf('snap') > -1 || request.input.toLowerCase().indexOf('camera') > -1) {
                    setTimeout(() => {
                        callback([{ label: 'Take a snapshot', description: 'Take a snapshot', text: 'Take a snapshot' }]);
                    }, 1000);
                } else if (request.input.toLowerCase().startsWith('distance')) {
                    setTimeout(() => {
                        callback([{ label: 'Measure distance', description: 'Take a snapshot', text: 'Take a snapshot' }]);
                    }, 1000);
                } else if (request.input.toLowerCase().startsWith('distance')) {
                    setTimeout(() => {
                        callback([{ label: 'Measure distance', description: 'Take a snapshot', text: 'Take a snapshot' }]);
                    }, 1000);
                } else {
                    callback([]);
                }
            }
        };
        if (!autocompleteService.current) {
            return [];
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <br />
                <br />
                <br />
                <Fab aria-label="close" onClick={closeSearch} color="gray" className="legendClose">
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <p></p>
                            <p></p>
                            <p></p>
                        </Grid>
                    </Grid>
                </Stack>

                <Stack spacing={2} sx={{ backgroundColor: '#fff', width: '100%', overflowY: 'auto', position: 'relative', zIndex: 1800 }}>
                    <Grid
                        container
                        spacing={3}
                        sx={{ width: '100%', marginTop: 'unset', marginTop: '12px', marginBottom: '12px', marginLeft: '-15px' }}
                    >
                        <Autocomplete
                            freeSolo
                            id="google-map-demo"
                            sx={{ width: 300, transform: 'translateX(15px)' }}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            loading={loading}
                            value={value}
                            noOptionsText=""
                            onChange={(event, newValue) => {
                                setOptions(newValue ? [newValue, ...options] : options);
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Run a command"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                        startAdornment: (
                                            <React.Fragment>
                                                <SearchIcon />
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                            renderOption={(props, option) => {
                                console.log('Matched', option);
                                const matches = [option] || [];

                                const parts = matches;
                                console.log('Parts :', parts);

                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                {parts.map((part, index) => (
                                                    <Box
                                                        key={index}
                                                        component="span"
                                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                                    >
                                                        {part.text}
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

SearchTool.propTypes = {
    assetInfo: PropTypes.object,
    closeSearch: PropTypes.func
};
export default SearchTool;
