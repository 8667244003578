import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

const Tools = ({ tools, open, toggleOpen, setActiveTool }) => {
    const theme = useTheme();

    const handleDrawerOpen = () => {
        toggleOpen();
    };

    const handleDrawerClose = () => {
        toggleOpen();
    };

    return (
        <React.Fragment>
            <ScopedCssBaseline>
                <Drawer
                    onClick={toggleOpen}
                    variant="permanent"
                    anchor="right"
                    PaperProps={{
                        sx: {
                            maxWidth: '40px',
                            height: 'unset',
                            top: 'unset',
                            bottom: 0,
                            transform: open ? 'translateX(-304px)' : 'unset',
                            visibility: 'hidden'
                        }
                    }}
                >
                    <Divider />
                    <List>
                        {tools.children?.map((tool, index) => {
                            const Icon = tool.icon;
                            const itemIcon = tool.icon ? (
                                <Icon style={{ fontSize: '20px', width: '20px', transform: open ? 'translateX(-2px)' : 'unset' }} />
                            ) : null;
                            switch (tool.type) {
                                case 'item':
                                    return (
                                        <ListItem
                                            onClick={() => {
                                                setActiveTool(tool.id);
                                                console.log('Clicked tool ', tool);
                                            }}
                                            id={tool.id}
                                            key={tool.id}
                                            disablePadding
                                            sx={{ display: 'block' }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    padding: '13px !important',
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {itemIcon}
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                case 'divider':
                                    return <Divider key={tool.id} />;
                            }
                        })}
                    </List>
                </Drawer>
            </ScopedCssBaseline>
        </React.Fragment>
    );
};

Tools.propTypes = {
    tools: PropTypes.object,
    open: PropTypes.bool,
    toggleOpen: PropTypes.func,
    setActiveTool: PropTypes.func
};

export default Tools;
