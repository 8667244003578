import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SurveyTool = ({ assetInfo, closeSurvey }) => {
    return (
        <React.Fragment>
            <Box id="surveyTool" sx={{ padding: '15px' }}>
                <h1 style={{ fontSize: '24px' }}>Survey Tool</h1>
                <Fab aria-label="close" onClick={closeSurvey} color="gray" className="surveyClose">
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <div>
                        <div className="filterToolLabel">Imported Model</div>
                        <TextField
                            className="removeBorder filterToolText"
                            disabled
                            variant="standard"
                            value={window.pickedAssetDetails.name?.replace('./models/', '')}
                        />
                    </div>
                    <div>
                        <div className="filterToolLabel">Location</div>

                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField className="filterToolText" disabled label="Latitude" value={window.pickedAssetDetails.latitude} />
                            <TextField className="filterToolText" disabled label="Longitude" value={window.pickedAssetDetails.longitude} />
                        </Box>
                        <br />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField className="filterToolText" disabled label="Rotation" value={' . '} />
                            <TextField
                                className="filterToolText"
                                disabled
                                label="Elevation to sea"
                                value={window.pickedAssetDetails.height}
                            />
                        </Box>
                    </div>
                    <div>
                        <div className="filterToolLabel">Size</div>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Select
                                className="filterToolText"
                                disabled
                                value={'Meters'}
                                label="Sizes"
                                sx={{ height: '42px', marginTop: '5px', marginRight: '5px', width: '50%' }}
                            >
                                <MenuItem value={'Meters'}>Meters</MenuItem>
                            </Select>
                            <TextField className="filterToolText" disabled label="Manual Scale" value={1} />
                        </Box>
                        <br />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField className="filterToolText" disabled label="Width" value={1} />
                            <TextField className="filterToolText" disabled label="Length" value={1} />
                            <TextField className="filterToolText" disabled label="Height" value={1} />
                        </Box>
                    </div>
                    <div>
                        <div className="filterToolLabel">Clicked point</div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointLabel">Latitude</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointText">{window.pickedAssetDetails.latitude}</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointLabel">Longitude</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointText">{window.pickedAssetDetails.longitude}</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointLabel">Sea Elevation</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointText">{window.pickedAssetDetails.height}</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointLabel">Ground Height</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="clickedPointText">{window.pickedAssetDetails.height}</div>
                            </Grid>
                        </Grid>
                    </div>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

SurveyTool.propTypes = {
    assetInfo: PropTypes.object,
    closeSurvey: PropTypes.func
};
export default SurveyTool;
