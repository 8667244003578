import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    // const auth = useSelector((state) => state.auth.value);
    // if (auth.isAuthenticated()) {
    //     return children;
    // }
    return children;
    // auth.login();
    // return null;
};

ProtectedRoute.propTypes = {
    children: PropTypes.node
};

export default ProtectedRoute;
