import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, AppBarProps, IconButton, Hidden, Link, Toolbar, useMediaQuery } from '@mui/material';
import { Box, Stack } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import { NotificationsNone, Send } from '@mui/icons-material';
import HeaderContent from './HeaderContent';
import { useNavigate, useLocation } from 'react-router-dom';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Nav = ({ path }) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Link
            onClick={() => navigate(`/${path}`)}
            underline={'none'}
            sx={{
                color: location.pathname.includes(path) ? '#F36F4B' : '#CCCCCC',
                borderBottom: location.pathname.includes(path) ? 'solid' : 'none',
                borderColor: '#F36F4B',
                marginLeft: 1,
                marginRight: 1,
                paddingBottom: '8px',
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {
                    color: '#F36F4B',
                    fontWeight: 'bold',
                    borderBottom: 'solid'
                }
            }}
        >
            {path}
        </Link>
    );
};

Nav.propTypes = {
    path: PropTypes.string
};

const Header = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    // const auth = useSelector((state) => state.auth.value);
    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // common header
    const mainHeader = (
        <Toolbar sx={{ display: 'inline-grid', maxHeight: '40px', paddingTop: 0, paddingBottom: 0 }}>
            {/* <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton> */}
            <HeaderContent />
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid #1E1D30`,
            backgroundColor: '#1E1D30'
        }
    };
    const bullet = <div style={{ border: '2px solid #8E8E98', width: 10, height: 10, borderRadius: '50%' }} />;
    const divider = <div style={{ borderLeft: '2px solid #363C4F', height: 40, marginLeft: 10, marginRight: 10 }} />;

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
