import React from 'react';
import PropTypes from 'prop-types';

class DialerKnob extends React.Component {
    constructor(props) {
        super(props);
        this.fullAngle = props.degrees;
        this.startAngle = (360 - props.degrees) / 2;
        this.endAngle = this.startAngle + props.degrees;
        this.margin = props.size * 0.15;
        // this.currentDeg = Math.floor(this.convertRange(props.min, props.max, this.startAngle, this.endAngle, props.value));
        this.state = { deg: props.value - 270, dragging: false };
    }

    componentDidUpdate(prevProps) {
        if (!this.state.dragging && this.props.value !== prevProps.value) {
            // let newValue = Math.floor(this.convertRange(this.props.min, this.props.max, this.startAngle, this.endAngle, this.props.value));
            this.currentDeg = this.props.value * (this.props.value / this.props.valueParser(this.props.value));
            if (this.currentDeg === this.startAngle) this.currentDeg--;
            this.setState({ deg: this.currentDeg - 270 });
        }
    }

    startDrag = (e) => {
        this.setState({ dragging: true });
        e.preventDefault();
        const knob = e.target.getBoundingClientRect();
        const pts = {
            x: knob.left + knob.width / 2,
            y: knob.top + knob.height / 2
        };
        const moveHandler = (e) => {
            this.currentDeg = this.getDeg(e.clientX, e.clientY, pts);
            if (this.currentDeg === this.startAngle) this.currentDeg--;
            // let newValue = Math.floor(this.convertRange(this.startAngle, this.endAngle, this.props.min, this.props.max, this.currentDeg));
            this.setState({ deg: this.currentDeg });
            this.props.onChange(this.currentDeg - 90);
        };
        document.addEventListener('mousemove', moveHandler);
        document.addEventListener('mouseup', (e) => {
            document.removeEventListener('mousemove', moveHandler);
            this.setState({ dragging: false });
        });
    };

    getDeg = (cX, cY, pts) => {
        const x = cX - pts.x;
        const y = cY - pts.y;
        let deg = (Math.atan(y / x) * 180) / Math.PI;
        if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
            deg += 90;
        } else {
            deg += 270;
        }
        let finalDeg = Math.min(Math.max(this.startAngle, deg), this.endAngle);
        return finalDeg;
    };

    // convertRange = (oldMin, oldMax, newMin, newMax, oldValue) => {
    //     return ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
    // };

    renderTicks = () => {
        let ticks = [];
        const incr = this.fullAngle / this.props.numTicks;
        const size = this.margin + this.props.size / 2;
        for (let deg = this.startAngle; deg <= this.endAngle; deg += incr) {
            const tick = {
                deg: deg,
                degNum: Math.ceil((deg + 180) % 360),
                showDegNum: [0, 90, 180, 270, 360].indexOf(Math.ceil((deg + 180) % 360)) >= 0,
                tickStyle: {
                    height: size + 10,
                    left: size - 1,
                    top: size + 2,
                    backgroundColor: '#8E8E98',
                    width: '1px',
                    transform: 'rotate(' + deg + 'deg)',
                    transformOrigin: 'top'
                },
                tickStyleBold: {
                    height: size + 10,
                    backgroundColor: '#8E8E98',
                    width: '3px',
                    borderRadius: '10px',
                    left: size - 1,
                    top: size + 2,
                    transform: 'rotate(' + deg + 'deg)',
                    transformOrigin: 'top'
                },
                tickNumStyle: {
                    fontSize: '10px',
                    position: 'absolute',
                    color: '#8E8E98',
                    transform: 'translateX(90px) translateY(-7px) rotate(-' + deg + 'deg)'
                }
            };
            ticks.push(tick);
        }
        return ticks;
    };

    dcpy = (o) => {
        return JSON.parse(JSON.stringify(o));
    };

    render() {
        let kStyle = {
            width: this.props.size,
            height: this.props.size
        };
        let iStyle = this.dcpy({
            width: '100px',
            height: '100px',
            maxWidth: '40px',
            maxHeight: '40px',
            marginTop: '0px',
            marginLeft: '0px'
        });
        let oStyle = this.dcpy({
            minWidth: '100px',
            minHeight: '100px',
            display: 'grid',
            placeContent: 'center',
            width: '100px',
            height: '100px'
        });
        oStyle.margin = this.margin;
        if (this.props.color) {
            oStyle.backgroundImage = 'radial-gradient(100% 70%,#8E8E98, #8E8E98)';
        }
        iStyle.transform = 'rotate(' + this.state.deg + 'deg)';

        return (
            <div className="dial">
                <div className="knob" style={kStyle}>
                    <div className="ticks">
                        {this.props.numTicks
                            ? this.renderTicks().map((tick, i) => (
                                  <div
                                      key={i}
                                      className={'tick' + (tick.deg <= this.currentDeg ? ' active' : '')}
                                      style={tick.showDegNum ? tick.tickStyleBold : tick.tickStyle}
                                  >
                                      {!this.props.hideNumbers && (
                                          <div style={tick.tickNumStyle}>{tick.showDegNum ? this.props.valueParser(tick.degNum) : ''}</div>
                                      )}
                                  </div>
                              ))
                            : null}
                    </div>
                    <div role="button" tabIndex={0} className="knob outer" style={oStyle} onMouseDown={this.startDrag}>
                        <div className="knob inner" style={iStyle}>
                            <div className="grip" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DialerKnob.propTypes = {
    degrees: PropTypes.number,
    hideNumbers: PropTypes.bool,
    size: PropTypes.number,
    numTicks: PropTypes.number,
    degrees: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.bool,
    onChange: PropTypes.func,
    valueParser: PropTypes.func
};

DialerKnob.defaultProps = {
    size: 150,
    min: 10,
    max: 30,
    hideNumbers: false,
    numTicks: 0,
    degrees: 360,
    valueParser: (val) => val,
    value: 0
};

export default DialerKnob;
