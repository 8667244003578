import { useRoutes } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import CallbackRoutes from './CallbackRoutes';
import TwinupWorld from './TwinupWorld';
import { useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // const auth = useSelector((state) => state.auth.value);
    return useRoutes([MainRoutes, CallbackRoutes, TwinupWorld]);
}
