import { lazy } from 'react';

import Cookies from 'js-cookie';
// project import
import Loadable from 'components/Loadable';
import ProtectedRoute from 'auth/ProtectedRoute';

// render - dashboard
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Login = Loadable(lazy(() => import('pages/login')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MainLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/',
            element: (
                <ProtectedRoute>
                    <DashboardDefault />
                </ProtectedRoute>
            )
        },
        {
            path: '/twinworld',
            element: (
                <ProtectedRoute>
                    <DashboardDefault />
                </ProtectedRoute>
            )
        },
        {
            path: '/world',
            element: (
                <ProtectedRoute>
                    <DashboardDefault />
                </ProtectedRoute>
            )
        }
    ]
};

export default MainRoutes;
