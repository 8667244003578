// assets
import Icon from '@ant-design/icons';

import { ReactComponent as InfoIconSvg } from '../icons/RightToolbar/Info.svg';
import { ReactComponent as TransformIconSvg } from '../icons/RightToolbar/Transform.svg';
import { ReactComponent as MeasurementIconSvg } from '../icons/RightToolbar/Measurement.svg';
import { ReactComponent as ShowHideIconSvg } from '../icons/RightToolbar/ShowHide.svg';
import { ReactComponent as StyleIconSvg } from '../icons/RightToolbar/Style.svg';
import { ReactComponent as ModelingIconSvg } from '../icons/RightToolbar/Modeling.svg';
import { ReactComponent as LinkIconSvg } from '../icons/RightToolbar/Link.svg';
import { ReactComponent as AnnotationIconSvg } from '../icons/RightToolbar/Annotation.svg';
import { ReactComponent as PovIconSvg } from '../icons/RightToolbar/Pov.svg';
import { ReactComponent as RobotIconSvg } from '../icons/RightToolbar/Robot.svg';
import { ReactComponent as AttachIconSvg } from '../icons/RightToolbar/Attach.svg';
import { ReactComponent as ChatIconSvg } from '../icons/RightToolbar/Chat.svg';
import { ReactComponent as AssessmentIconSvg } from '../icons/RightToolbar/Magnify.svg';
import { ReactComponent as LegendIconSvg } from '../icons/RightToolbar/Legend.svg';

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;

// icons
const icons = {
    InfoIcon: (props) => SvgIcon(InfoIconSvg, props),
    TransformIcon: (props) => SvgIcon(TransformIconSvg, props),
    MeasurementIcon: (props) => SvgIcon(MeasurementIconSvg, props),
    ShowHideIcon: (props) => SvgIcon(ShowHideIconSvg, props),
    StyleIcon: (props) => SvgIcon(StyleIconSvg, props),
    ModelingIcon: (props) => SvgIcon(ModelingIconSvg, props),
    LinkIcon: (props) => SvgIcon(LinkIconSvg, props),
    AnnotationIcon: (props) => SvgIcon(AnnotationIconSvg, props),
    PovIcon: (props) => SvgIcon(PovIconSvg, props),
    RobotIcon: (props) => SvgIcon(RobotIconSvg, props),
    AttachIcon: (props) => SvgIcon(AttachIconSvg, props),
    ChatIcon: (props) => SvgIcon(ChatIconSvg, props),
    LegendIcon: (props) => SvgIcon(LegendIconSvg, props),
    AssessmentIcon: (props) => SvgIcon(AssessmentIconSvg, props)
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const tools = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Legend',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.LegendIcon,
            breadcrumbs: false
        },
        {
            id: 'AssessSite',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.AssessmentIcon,
            breadcrumbs: false
        }
    ]
};

export default tools;
