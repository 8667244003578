// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
import { useSelector } from 'react-redux';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    // const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    // const auth = useSelector((state) => state.auth.value);
    return (
        <>
            {/*matchesXs && */ <Box sx={{ width: '100%', ml: 1 }} />}
            <div style={{ width: '100%' }}></div>
            <Profile />
        </>
    );
};

export default HeaderContent;
