import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';

const MarqueTool = ({ assetInfo, closeMarque }) => {
    const [checkedBuildingTypes, setCheckedBuildingTypes] = React.useState(new Set());

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <h1 style={{ fontSize: '24px' }}>Points of interest</h1>
                <Fab
                    aria-label="close"
                    onClick={closeMarque}
                    color="gray"
                    className="legendClose"
                    sx={{
                        border: 'none !important',
                        boxShadow: 'none'
                    }}
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                {/* <Stack spacing={2} sx={{ width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <p>
                                Press <b>Shift</b> and <b>Click</b> on map to put vertices of selection.
                            </p>
                            <p>
                                Once the area is selected, press <b>Shift</b> and <b>Right-Click</b> to finish selection.
                            </p>
                            <p>
                                To cancel selection, press <b>Shift</b> and <b>Double-Click</b>
                            </p>
                        </Grid>
                    </Grid>
                </Stack> */}
                {window.pointOfInterests && window.pointOfInterests.length > 0 && (
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        {/* <h3>Points of interest: </h3> */}
                        <Stack spacing={2} sx={{ width: '100%', height: '47vh', overflowY: 'scroll', fontSize: '11px' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    Name
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    Coordinate
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    Distance
                                </Grid>
                            </Grid>
                            <div className="points-of-interest-places">
                                {window.pointOfInterests.map((poi) => {
                                    return (
                                        <Grid key={poi.id} container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                {poi.name}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {`(${poi.coordinate.latitude}, ${poi.coordinate.longitude})`}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {poi.distance}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </div>
                        </Stack>
                    </Stack>
                )}
            </Box>
        </React.Fragment>
    );
};

MarqueTool.propTypes = {
    assetInfo: PropTypes.object,
    closeMarque: PropTypes.func
};
export default MarqueTool;
