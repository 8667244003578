import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, ButtonGroup, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import TerrainLayout from 'layout/TerrainLayout';
import FiltersLayout from 'layout/FiltersLayout';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ scene_id, scene_folder }) => {
    // const auth = useSelector((state) => state.auth.value);
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const [showComponent, setShowComponent] = useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    function renderComponent() {
        return (
            <Box
                component="main"
                sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 }, paddingRight: '0 !important', paddingLeft: '0 !important' }}
            >
                <TerrainLayout scene_id={scene_id} scene_folder={scene_folder} />
                <FiltersLayout />
            </Box>
        );
    }

    function renderDrawer() {
        return <Drawer open={open} sx={{ transform: 'translateY(95px)' }} handleDrawerToggle={handleDrawerToggle} />;
    }

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        const cesiumAppStyle = document.createElement('link');
        cesiumAppStyle.rel = 'stylesheet';
        // cesiumAppStyle.href = 'https://twinworld-component.s3.amazonaws.com/build/utils/App/AppStyle.css';
        cesiumAppStyle.href = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/AppStyle_dev.css';
        document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumAppStyle);
        const searchSceneId =
            window.location.pathname.indexOf('twinworld') >= 0 ? window.location.pathname.split('/').splice(-1)[0] : undefined;
        if (!searchSceneId) {
            setTimeout(() => {
                if (document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg')) {
                    //document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg').style.display = 'none';
                }
            }, 5000);
        } else {
            setTimeout(() => {
                if (document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg')) {
                    //document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg').style.display = 'none';
                }
            }, 8000);
        }
        setTimeout(() => {
            setShowComponent(true);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <div className="blackbg">
                <div className="loader"></div>
            </div>
            {showComponent && renderDrawer()}
            {showComponent && renderComponent()}
        </Box>
    );
};

MainLayout.propTypes = {
    scene_id: PropTypes.string,
    scene_folder: PropTypes.string
};

export default MainLayout;
