// material-ui
import { useMediaQuery, Box, Chip, Stack } from '@mui/material';
import PropTypes from 'prop-types';
// project import
import Profile from './Profile';
import User from './User';
import Menu from './menu';
import { Grid, Typography } from '@mui/material';
import { AppBar, AppBarProps, IconButton, Hidden, Link, Toolbar } from '@mui/material';
import { NotificationsNone, Send } from '@mui/icons-material';
import Notifications from './Notifications';
import MobileSection from './MobileSection';
import Icon from '@ant-design/icons';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { ReactComponent as WeatherIconSvg } from '../../../../icons/WeatherTool/weather.svg';

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;

const icons = {
    WeatherIcon: (props) => SvgIcon(WeatherIconSvg, props)
};
// ==============================|| HEADER - CONTENT ||============================== //

const App = ({ icon }) => {
    return (
        <Link
            sx={{
                color: '#CCCCCC',
                cursor: 'pointer',
                '&:hover': {
                    color: '#F36F4B'
                }
            }}
        >
            {icon}
        </Link>
    );
};

App.propTypes = {
    icon: PropTypes.element
};

const HeaderContent = () => {
    // const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    // const auth = useSelector((state) => state.auth.value);
    const [taskList, setTaskList] = useState([]);
    const cookie = Cookies.get('dev-twinup');
    const bullet = <div style={{ border: '2px solid #8E8E98', width: 10, height: 10, borderRadius: '50%' }} />;
    const divider = <div style={{ borderLeft: '2px solid #363C4F', height: 40, marginLeft: 10, marginRight: 10 }} />;
    useEffect(() => {
        const twinupCookie = Cookies.get('dev-twinup');
        const cookieData = JSON.parse(twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, ''));
        const accesToken = cookieData.access_token;
    }, []);
    return (
        <Box sx={{ display: 'inline-flex' }}>
            {/* {matchesXs && <Box sx={{ width: '0', ml: 1 }} />} */}

            <div
                style={{ display: 'grid', placeContent: 'center', cursor: 'pointer' }}
                onClick={() => {
                    window.location.path = '/';
                }}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
            >
                <img alt={'TwinUp'} style={{ width: '25px' }} src={'https://dtuas2sql0dg4.cloudfront.net/build/utils/lib/TwinUp.svg'} />
            </div>
            <div id="objectModels" style={{ width: '0', display: 'flex', flexFlow: 'row nowrap' }}></div>
            <div id="snapshots" style={{ width: 'calc(100vw - 440px)', display: 'flex', flexFlow: 'row nowrap' }}></div>
            {/* <Profile /> */}
            <Stack direction="row" alignItems="center">
                <Stack direction="row" alignItems="center" sx={{ marginRight: '10px' }}>
                    {cookie && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                                    <a
                                        style={{
                                            width: '70px',
                                            color: '#CCCCCC',
                                            borderColor: '#F36F4B',
                                            marginLeft: 1,
                                            marginRight: 1,
                                            marginTop: 1,
                                            paddingBottom: '8px',
                                            textTransform: 'capitalize',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: '#F36F4B',
                                                fontWeight: 'bold',
                                                borderBottom: 'solid'
                                            }
                                        }}
                                        href="/community"
                                        onClick={() => {
                                            window.location.pathname = '/community';
                                        }}
                                        className="header-menu-item"
                                    >
                                        Community
                                    </a>
                                </Grid>
                                <Grid item xs={4} style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                                    <a
                                        style={{
                                            width: '70px',
                                            color: '#CCCCCC',
                                            borderColor: '#F36F4B',
                                            marginLeft: 1,
                                            marginTop: 1,
                                            marginRight: 1,
                                            paddingBottom: '8px',
                                            textTransform: 'capitalize',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: '#F36F4B',
                                                fontWeight: 'bold',
                                                borderBottom: 'solid'
                                            }
                                        }}
                                        href="/building"
                                        onClick={() => {
                                            window.location.pathname = '/building';
                                        }}
                                        className="header-menu-item"
                                    >
                                        Building
                                    </a>
                                </Grid>
                                <Grid item xs={4} style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                                    <a
                                        href="#"
                                        className="header-menu-item"
                                        style={{ color: '#F36F4B', borderBottom: '2px solid #F36F4B' }}
                                    >
                                        World
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
                {/* {divider}
                <App icon={<Send sx={{ transform: 'rotate(-45deg)' }} />} /> */}
                {/* {divider} */}
                {/* <Stack direction="row" alignItems="center">
                <Stack direction="row" alignItems="center" sx={{ marginRight: '10px' }}>
                    <Notifications />
                </Stack>
                <User />
            </Stack> */}
                {divider}
                <IconButton
                    style={{ fontSize: '25px' }}
                    sx={{ fontSize: '16px' }}
                    onClick={() => {
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#weatherVisualizer').click();
                    }}
                >
                    <App style={{ fontSize: '16px' }} icon={icons.WeatherIcon()} />
                </IconButton>
                {divider}
                <IconButton
                // onClick={() => {
                //     document.querySelector('twinworld-app').shadowRoot.querySelector('#weatherVisualizer').click();
                // }}
                >
                    <App icon={<NotificationsNone />} />
                </IconButton>
                {divider}
                <Menu />
            </Stack>
        </Box>
    );
};

export default HeaderContent;
