import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const colors = {
    // apartment: '#03c7cd',
    // hospital: '#ff7376',
    // commercial: 'rgb(192 199 3)',
    // school: '#11700c'
    apartment: Cesium.Color(1, 1, 1),
    hospital: Cesium.Color(1, 1, 1),
    commercial: Cesium.Color(1, 1, 1),
    school: Cesium.Color(1, 1, 1)
};

const fixWorldColors = (checkedBuildingTypes, requestedCoverage, unitOfCoverage) => {
    fetch(``);
    window.colorConditions = [];
    let distanceAdjustment = '';
    /*if (!isNaN(parseInt(requestedCoverage))) {
        distanceAdjustment =
            '${distance}*6371 > ' +
            (unitOfCoverage === 'kilometer'
                ? requestedCoverage
                : unitOfCoverage === 'mile'
                ? requestedCoverage * 0.621371
                : unitOfCoverage === 'feet'
                ? requestedCoverage * 3280.84
                : 1) +
            ' && ';
    }*/
    console.log(distanceAdjustment);
    if (checkedBuildingTypes.has('apartments')) {
        colorConditions.push([
            distanceAdjustment +
                "(${feature['building']} === 'apartments' || ${feature['building']} === 'residential' || ${feature['building']} === 'house')",
            `color('${colors.apartment}')`
        ]);
    }
    if (checkedBuildingTypes.has('hospitals')) {
        colorConditions.push([distanceAdjustment + "${feature['building']} === 'hospital'", `color('${colors.hospital}')`]);
    }
    if (checkedBuildingTypes.has('offices')) {
        colorConditions.push([distanceAdjustment + "${feature['building']} === 'commercial'", `color('${colors.commercial}')`]);
    }
    if (checkedBuildingTypes.has('schools')) {
        colorConditions.push([distanceAdjustment + "${feature['building']} === 'school'", `color('${colors.school}')`]);
    }

    buildingsTileset.style =
        window.hideBuildingsStr && window.hideBuildingsStr !== ''
            ? new Cesium.Cesium3DTileStyle({
                  //   defines: {
                  //       distance: "distance(vec2(${feature['cesium#longitude']}, ${feature['cesium#latitude']}), vec2(144.96007, -37.82249))"
                  //   },
                  color: {
                      conditions: colorConditions
                  },
                  show: window.hideBuildingsStr
              })
            : new Cesium.Cesium3DTileStyle({
                  //   defines: {
                  //       distance: "distance(vec2(${feature['cesium#longitude']}, ${feature['cesium#latitude']}), vec2(144.96007, -37.82249))"
                  //   },
                  color: {
                      conditions: colorConditions
                  }
              });

    /*
    buildingsTileset.style = new Cesium.Cesium3DTileStyle({
        color: {
            conditions: [
                ["${feature['building']} === 'apartments' || ${feature['building']} === 'residential'", "color('cyan')"],
                ["${feature['building']} === 'hospital'", "color('rgb(255, 115, 118)')"],
                ["${feature['building']} === 'commercial'", "color('yellow')"],
                ["${feature['building']} === 'school'", "color('green')"],
                [true, "color('white')"]
            ]
        }
    });
    */
};

export default function SearchOption(props) {
    const [searchText, setSearchText] = React.useState('');

    return (
        <Paper
            component="div"
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 340,
                height: 40,
                backgroundColor: '#363C4F',
                border: '1px solid #5E667A',
                borderRadius: '10px',
                color: '#fff'
            }}
        >
            <InputBase
                placeholder={props.placeholder}
                value={searchText}
                sx={{ ml: 1, flex: 1, color: '#fff' }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event) => {
                    setSearchText(event.target.value);
                }}
                onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                        console.log('Enter pressed with text', event.target.value);
                        const searchText = event.target.value;
                        event.preventDefault();
                        event.stopPropagation();
                        document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'none';
                        fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                userPrompt: `Give me only the search item out of (hospital, apartments, commercial) and the coverage area and unit of the coverage area out of (kilometer, meter, mile, feet) of search which is requested in the following text. Return only json object with keys search_option and requested_coverage and unit_of_requested_coverage with values in lower case : ${searchText}`
                            })
                        })
                            .then((archieRes) => archieRes.json())
                            .then((archieResJsonTotal) => {
                                let coverageMeters = 0;
                                const archieResJson = archieResJsonTotal.response;
                                const unit = archieResJson.unit_of_requested_coverage;
                                const value = parseFloat(archieResJson.requested_coverage);
                                if (unit.toLowerCase().indexOf('kilometer') > -1) {
                                    coverageMeters = value * 1000;
                                } else if (unit.toLowerCase().indexOf('meter') > -1) {
                                    coverageMeters = value;
                                } else if (unit.toLowerCase().indexOf('mile') > -1) {
                                    coverageMeters = value * 1609.34;
                                } else if (unit.toLowerCase().indexOf('feet') > -1 || unit.toLowerCase().indexOf('foot') > -1) {
                                    coverageMeters = value * 0.3048;
                                }
                                window.scanState = 'scanning';
                                window.entityList = [];
                                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility =
                                    'visible';
                                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                if (window.poiFlagPoints) {
                                    window.poiFlagPoints.forEach((poiFlagPoint) => {
                                        viewer.entities.remove(poiFlagPoint);
                                    });
                                }
                                window.poiFlagPoints = [];
                                window.locationIntelSearchData = {
                                    latitudeDegree: (window.destCenter.latitude * 180) / Cesium.Math.PI,
                                    longitudeDegree: (window.destCenter.longitude * 180) / Cesium.Math.PI,
                                    buildingType: archieResJson.search_option,
                                    requestedCoverageMeters: coverageMeters,
                                    sceneId: window.newSceneId
                                };
                                fetch('https://dev.thetwinmaster.com/twinworld/getLocationIntel', {
                                    method: 'POST',
                                    credentials: 'include',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify(window.locationIntelSearchData)
                                })
                                    .then((res) => res.json())
                                    .then((resJson) => {
                                        window.allPickedAssets = resJson;
                                        window.changeOriginOnClickGlobal = false;
                                        // window.startRobotScanner(200, true, false);
                                        window.scanState = 'complete';
                                        window.entityList = Array.from(
                                            new Set(
                                                resJson
                                                    .filter((pickedAsset) => {
                                                        return pickedAsset.name && !pickedAsset.name.endsWith('undefined )');
                                                    })
                                                    .filter((pickedAsset) => pickedAsset.name !== window.currentAssetName)
                                                    .map((pickedAsset) => (
                                                        <span>
                                                            {pickedAsset.name.split('( Status')[0]}
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {'    ( Status' + pickedAsset.name.split('( Status')[1]}
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lat:{' '}
                                                            {pickedAsset.latitude.toFixed(3)}&nbsp; Lon: {pickedAsset.longitude.toFixed(3)}
                                                        </span>
                                                    ))
                                            )
                                        );
                                        resJson
                                            .filter((data) => {
                                                return !data.name.endsWith('undefined )');
                                            })
                                            .forEach((formattedPoi, index) => {
                                                var flag = new Cesium.PinBuilder();
                                                var flagProperties = new Cesium.PropertyBag();
                                                flagProperties.addProperty('MyProperty_A', 0);
                                                flagProperties.addProperty('MyProperty_B', 1);
                                                // Create an Entity
                                                var flagPoint = viewer.entities.add({
                                                    name: 'pole',
                                                    position: Cesium.Cartesian3.fromDegrees(
                                                        formattedPoi.longitude,
                                                        formattedPoi.latitude,
                                                        window.destinationSeaLevelHeight
                                                    ),
                                                    billboard: {
                                                        image: flag.fromUrl(
                                                            formattedPoi.pinIcon,
                                                            new Cesium.Color.fromCssColorString('#1e90ff99'),
                                                            50
                                                        ),
                                                        // image: flag.fromUrl(formattedPoi.pinIcon, Cesium.Color.DODGERBLUE, 50),
                                                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                                    },
                                                    properties: flagProperties
                                                });
                                                window.poiFlagPoints.push(flagPoint);
                                                var flagNumPoint = viewer.entities.add({
                                                    name: 'pole',
                                                    position: Cesium.Cartesian3.fromDegrees(
                                                        formattedPoi.longitude,
                                                        formattedPoi.latitude,
                                                        window.destinationSeaLevelHeight
                                                    ),
                                                    billboard: {
                                                        image: flag.fromText(index + 1, Cesium.Color.DODGERBLUE, 25).toDataURL(),
                                                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                                    },
                                                    properties: flagProperties
                                                });
                                                window.poiFlagPoints.push(flagNumPoint);
                                            });
                                        document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
                                        setTimeout(() => {
                                            document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
                                        }, 20);
                                        viewer.camera.flyTo({
                                            destination: viewer.scene.camera.position,
                                            orientation: {
                                                heading: viewer.camera.heading - 0.001,
                                                pitch: viewer.camera.pitch,
                                                roll: 0.0
                                            }
                                        });
                                    });

                                // document
                                //     .querySelector('twinworld-app')
                                //     .shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display = 'none';
                                document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                            });
                    }
                }}
            />
            <IconButton type="button" sx={{ p: '10px', color: '#fff' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}

SearchOption.propTypes = {
    placeholder: PropTypes.string
};
