import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const Basic2d3dSwitch = styled(Switch, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    padding: 8,
    width: '67px',
    height: '40px',
    'span > .MuiSwitch-thumb': {
        width: '23px',
        height: '23px'
    },
    '& .MuiSwitch-track': {
        borderRadius: '15px',
        width: '67px',
        height: '32px',
        backgroundColor: '#363C4F !important',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '45%',
            color: '#fff',
            width: 16,
            height: 16
        },
        '&:before': {
            content: '"2D"',
            fontSize: '11px',
            left: 12,
            transform: 'translateY(-3px)'
        },
        '&:after': {
            content: '"3D"',
            fontSize: '11px',
            right: 12,
            transform: 'translateY(-3px)'
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 23,
        height: 23,
        margin: 3,
        backgroundColor: '#fff'
    }
}));

export default Basic2d3dSwitch;
