import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@ant-design/icons';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as SunIconSvg } from '../../../icons/WeatherTool/sunShine.svg';
import { ReactComponent as WindGaugeIconSvg } from '../../../icons/WeatherTool/windGauge.svg';
import { ReactComponent as WindIconSvg } from '../../../icons/WeatherTool/wind.svg';
import { ReactComponent as PrecipitationIconSvg } from '../../../icons/WeatherTool/precipitation.svg';
import { ReactComponent as FeelsLikeIconSvg } from '../../../icons/WeatherTool/feelsLike.svg';
import { ReactComponent as HumidityIconSvg } from '../../../icons/WeatherTool/humidity.svg';
import { ReactComponent as VisibilityIconSvg } from '../../../icons/WeatherTool/visibility.svg';
import { ReactComponent as PressureIconSvg } from '../../../icons/WeatherTool/pressure.svg';

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;
const icons = {
    SunIcon: (props) => SvgIcon(SunIconSvg, props),
    WindIcon: (props) => SvgIcon(WindIconSvg, props),
    WindGaugeIcon: (props) => SvgIcon(WindGaugeIconSvg, props),
    PrecipitationIcon: (props) => SvgIcon(PrecipitationIconSvg, props),
    FeelsLikeIcon: (props) => SvgIcon(FeelsLikeIconSvg, props),
    HumidityIcon: (props) => SvgIcon(HumidityIconSvg, props),
    VisibilityIcon: (props) => SvgIcon(VisibilityIconSvg, props),
    PressureIcon: (props) => SvgIcon(PressureIconSvg, props)
};

const autocompleteService = { current: null };

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{ padding: '1px' }}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, padding: '1px' }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const WeatherTool = ({ latitude, longitude }) => {
    const theme = useTheme();
    const [checkedBuildingTypes, setCheckedBuildingTypes] = React.useState(new Set());
    const [actionValue, setActionValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [hoverOnGauge, setHoverOnGauge] = React.useState(false);
    const [loadingWeather, setLoadingWeather] = React.useState(false);

    const [weatherData, setWeatherData] = React.useState({
        placeName: '',
        temperature: 67,
        uvIndex: 'Moderate',
        uvProtectionTill: '',
        sunset: '6:41 PM',
        sunrise: '5:27 AM',
        windSpeed: '3',
        precipitation: '0',
        rain: '0',
        feelsLike: '',
        humidity: '',
        dewPoint: '',
        visibility: '',
        pressure: '',
        pollen: {
            grass: '',
            tree: '',
            weed: ''
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function pad(n) {
        return n < 10 ? '0' + n : n;
    }

    React.useEffect(() => {
        let active = true;
        setLoadingWeather(true);
        console.log('Getting weather details at ', window.destCenter);
        if (!window.destCenter && window.destBoundingBox) {
            window.destCenter = Cesium.Rectangle.center(window.destBoundingBox);
        }
        fetch(
            `https://dev.thetwinmaster.com/twinworld/getGeolocation?latitude=${
                window.destCenter ? (window.destCenter.latitude * 180) / Cesium.Math.PI : 41.86312
            }&longitude=${window.destCenter ? (window.destCenter.longitude * 180) / Cesium.Math.PI : -87.69718}`
        )
            .then((res) => res.text())
            .then((resText) => {
                const placeName = resText;
                fetch(
                    `https://dev.thetwinmaster.com/twinworld/getDailyWeather?latitude=${
                        window.destCenter ? (window.destCenter.latitude * 180) / Cesium.Math.PI : 41.86312
                    }&longitude=${window.destCenter ? (window.destCenter.longitude * 180) / Cesium.Math.PI : -87.69718}`
                )
                    .then((res) => res.json())
                    .then((resJson) => {
                        console.log('Daily weather', resJson);
                        const todaysWeather = resJson['CurrentWeatherData'];
                        const dateObj = new Date();
                        const localDate = pad(dateObj.getDate()) + '/' + pad(dateObj.getMonth() + 1) + '/' + dateObj.getFullYear();
                        const formattedLocalDate = localDate.split('/').reverse().join('-');

                        const todaysForeCast = resJson['ForecaseData'][formattedLocalDate];
                        console.log(todaysWeather);
                        console.log('Forecast for today :', resJson['ForecaseData'], formattedLocalDate, todaysForeCast);
                        console.log('UV Index for today :', todaysForeCast.uv_index_max);
                        fetch(
                            `https://dev.thetwinmaster.com/twinworld/getHourlyWeather?latitude=${
                                window.destCenter ? (window.destCenter.latitude * 180) / Cesium.Math.PI : 41.86312
                            }&longitude=${window.destCenter ? (window.destCenter.longitude * 180) / Cesium.Math.PI : -87.69718}&hour=${
                                new Date().toISOString().slice(0, 14) + '00'
                            }`
                        )
                            .then((res) => res.json())
                            .then((resJson) => {
                                console.log('Hourly weather', resJson);
                                const hourlyWeather = resJson;

                                fetch(
                                    `https://dev.thetwinmaster.com/twinworld/getPollenDataBeta?latitude=${
                                        window.destCenter ? (window.destCenter.latitude * 180) / Cesium.Math.PI : 41.86312
                                    }&longitude=${window.destCenter ? (window.destCenter.longitude * 180) / Cesium.Math.PI : -87.69718}`
                                )
                                    .then((res) => res.json())
                                    .then((pollenData) => {
                                        const pollenDataRisk = pollenData.dailyInfo[0].pollenTypeInfo;
                                        setWeatherData({
                                            placeName: placeName,
                                            temperature: todaysWeather.temperature,
                                            uvIndex: todaysForeCast.uv_index_max,
                                            // uvIndex: 12,
                                            uvProtectionTill: new Date(todaysForeCast.sunset).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                hour12: true
                                            }),
                                            sunrise: new Date(todaysForeCast.sunrise).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }),
                                            sunset: new Date(todaysForeCast.sunset).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }),
                                            windSpeed: todaysWeather.windspeed,
                                            precipitation: todaysForeCast.precipitation_sum,
                                            rain: todaysForeCast.rain_sum,
                                            // feelsLike: (todaysForeCast.apparent_temperature_max + todaysForeCast.apparent_temperature_min) / 2,
                                            feelsLike: hourlyWeather.apparent_temperature,
                                            humidity: hourlyWeather.relativehumidity_2m,
                                            dewPoint: hourlyWeather.dewpoint_2m,
                                            visibility: Math.floor(hourlyWeather.visibility / 1000),
                                            pressure: Math.floor((hourlyWeather.surface_pressure * 100) / hourlyWeather.pressure_msl),
                                            pollen: {
                                                grass: pollenDataRisk[0].indexInfo ? pollenDataRisk[0].indexInfo.value : 0,
                                                tree: pollenDataRisk[1].indexInfo ? pollenDataRisk[1].indexInfo.value : 0,
                                                weed: pollenDataRisk[2].indexInfo ? pollenDataRisk[2].indexInfo.value : 0
                                            }
                                        });
                                        setLoadingWeather(false);
                                    })
                                    .catch((err) => {
                                        setWeatherData({
                                            placeName: placeName,
                                            temperature: todaysWeather.temperature,
                                            uvIndex: todaysForeCast.uv_index_max,
                                            // uvIndex: 12,
                                            uvProtectionTill: new Date(todaysForeCast.sunset).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                hour12: true
                                            }),
                                            sunrise: new Date(todaysForeCast.sunrise).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }),
                                            sunset: new Date(todaysForeCast.sunset).toLocaleString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }),
                                            windSpeed: todaysWeather.windspeed,
                                            precipitation: todaysForeCast.precipitation_sum,
                                            rain: todaysForeCast.rain_sum,
                                            // feelsLike: (todaysForeCast.apparent_temperature_max + todaysForeCast.apparent_temperature_min) / 2,
                                            feelsLike: hourlyWeather.apparent_temperature,
                                            humidity: hourlyWeather.relativehumidity_2m,
                                            dewPoint: hourlyWeather.dewpoint_2m,
                                            visibility: Math.floor(hourlyWeather.visibility / 1000),
                                            pressure: Math.floor((hourlyWeather.surface_pressure * 100) / hourlyWeather.pressure_msl)
                                        });
                                        setLoadingWeather(false);
                                    });
                            });
                    });
            });
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
                    {/* <Tabs value={value} onChange={handleChange} aria-label="notification/weather tabs">
                        <Tab
                            sx={{
                                color: '#fff',
                                fontFamily: 'Suisse Intl',
                                fontSize: '14px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                color: '#D9D9D9 !important'
                            }}
                            label="Weather Dashboard"
                            {...a11yProps(1)}
                        />
                    </Tabs> */}
                    <Typography
                        sx={{
                            color: '#fff',
                            fontFamily: 'Suisse Intl',
                            fontSize: '14px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: '#D9D9D9 !important'
                        }}
                        {...a11yProps(1)}
                    >
                        Weather Dashboard
                    </Typography>
                </Box>
                {/* <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}> */}
                <Box /*value={value} index={0} dir={theme.direction}*/ style={{ padding: '0', overflowX: 'hidden' }}>
                    {window.destCenter && loadingWeather && (
                        <Stack
                            spacing={0}
                            sx={{
                                height: '500px',
                                width: '100%',
                                margin: 'auto',
                                display: 'grid',
                                placeContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Stack>
                    )}
                    {!window.destCenter && (
                        <Stack
                            spacing={0}
                            sx={{
                                height: '500px',
                                width: '100%',
                                margin: 'auto',
                                display: 'grid',
                                placeContent: 'center',
                                textAlign: 'center'
                                // position: 'absolute',
                                // zIndex: '1000',
                                // height: '650px',
                                // width: '91%',
                                // backgroundColor: '#363d4f'
                            }}
                        >
                            <Typography sx={{ fontSize: '16px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                Kindly select a location first from search box
                            </Typography>
                        </Stack>
                    )}
                    {(window.preloadWeatherStyles || (window.destCenter && !loadingWeather)) && (
                        <Stack spacing={0} sx={{ width: '100%' }}>
                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Grid container spacing={1} sx={{ margin: 'auto', display: 'grid', placeContents: 'center' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ margin: 'auto', fontSize: '19.5px', fontWeight: 'bold', color: '#fff' }}
                                    >
                                        {weatherData.placeName}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ margin: 'auto', fontSize: '14px', fontWeight: 'bold', color: '#fff' }}
                                    >
                                        {weatherData.temperature}&deg;&nbsp; | &nbsp; Partly cloudy
                                    </Grid>
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className="weatherWidgets" spacing={2} sx={{ width: '100%', marginLeft: '8px' }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.SunIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                UV INDEX
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            lineHeight: '14px !importanrt',
                                                            color: '#fff',
                                                            marginBottom: '1px'
                                                        }}
                                                    >
                                                        {weatherData.uvIndex < 3 && <span>Low</span>}
                                                        {weatherData.uvIndex >= 3 && weatherData.uvIndex < 6 && <span>Moderate</span>}
                                                        {weatherData.uvIndex >= 6 && weatherData.uvIndex < 8 && <span>High</span>}
                                                        {weatherData.uvIndex >= 8 && weatherData.uvIndex < 11 && <span>Very high</span>}
                                                        {weatherData.uvIndex >= 11 && <span>Extreeme</span>}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    {/* <Slider
                                                            className="uvIndexGauge"
                                                            value={Math.floor((weatherData.uvIndex * 100) / 14)}
                                                            defaultValue={Math.floor((weatherData.uvIndex * 100) / 14)}
                                                            valueLabelFormat={() => weatherData.uvIndex}
                                                            aria-label="Default"
                                                            valueLabelDisplay={hoverOnGauge}
                                                            // valueLabelDisplay="auto"
                                                        /> */}
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-25px', marginBottom: '8px' }}>
                                                    <span
                                                        class="MuiSlider-root MuiSlider-colorPrimary MuiSlider-sizeMedium css-1herioj-MuiSlider-root"
                                                        style={{
                                                            width: '100px',
                                                            height: '40px',
                                                            background:
                                                                "url('https://twinworld-component.s3.amazonaws.com/build/utils/lib/gauge.svg')",
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'cover',
                                                            backgroundColor: 'transparent',
                                                            borderTopLeftRadius: '110px',
                                                            borderTopRightRadius: '110px',
                                                            borderBottom: 0,
                                                            marginBottom: '1px',
                                                            transform: 'scale(1.2)',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        <span
                                                            class="MuiSlider-rail css-14pt78w-MuiSlider-rail"
                                                            style={{
                                                                display: 'none'
                                                            }}
                                                        ></span>
                                                        <span
                                                            class="MuiSlider-track css-1gv0vcd-MuiSlider-track"
                                                            style={{
                                                                width: '1px',
                                                                transform:
                                                                    weatherData.uvIndex < 3
                                                                        ? 'rotate(calc(-80deg)) translateX(-7px) translateY(46px) scale(5)'
                                                                        : weatherData.uvIndex >= 3 && weatherData.uvIndex < 6
                                                                        ? 'rotate(calc(-50deg)) translateX(21px) translateY(42px) scale(5)'
                                                                        : weatherData.uvIndex >= 6 && weatherData.uvIndex < 8
                                                                        ? 'rotate(calc(36deg)) translateX(49px) translateY(-21px) scale(5)'
                                                                        : weatherData.uvIndex >= 8 && weatherData.uvIndex < 11
                                                                        ? 'rotate(calc(50deg)) translateX(45px) translateY(-34px) scale(5)'
                                                                        : weatherData.uvIndex >= 11 && weatherData.uvIndex < 13
                                                                        ? 'rotate(calc(60deg)) translateX(40px) translateY(-40px) scale(5)'
                                                                        : 'rotate(calc(80deg)) translateX(26px) translateY(-53px) scale(5)',
                                                                height: '10%',
                                                                color: '#D9D9D9',
                                                                background:
                                                                    "url('https://twinworld-component.s3.amazonaws.com/build/utils/lib/gaugeHand.svg')",
                                                                backgroundRepeat: 'no-repeat',
                                                                border: 'none'
                                                            }}
                                                        ></span>
                                                        <span
                                                            data-index="0"
                                                            data-focusvisible="false"
                                                            class="MuiSlider-thumb MuiSlider-thumbColorPrimary MuiSlider-thumbSizeMedium css-1fy7z1y-MuiSlider-thumb"
                                                            style={{
                                                                left: '54%',
                                                                transform: 'translateY(4px) translateX(-10px) scale(0.5)',
                                                                display: 'none'
                                                            }}
                                                        >
                                                            <input
                                                                data-index="0"
                                                                aria-label="Default"
                                                                aria-valuenow="54"
                                                                aria-orientation="horizontal"
                                                                aria-valuemax="100"
                                                                aria-valuemin="0"
                                                                type="range"
                                                                min="0"
                                                                max="100"
                                                                step="1"
                                                                value="54"
                                                                style={{
                                                                    border: '0px',
                                                                    clip: 'rect(0px, 0px, 0px, 0px)',
                                                                    height: '100%',
                                                                    margin: '-1px',
                                                                    overflow: 'hidden',
                                                                    padding: '0px',
                                                                    position: 'absolute',
                                                                    whiteSpace: 'nowrap',
                                                                    width: '100%',
                                                                    direction: 'ltr'
                                                                }}
                                                            />
                                                            <span
                                                                class="MuiSlider-valueLabel css-1lp0hz7-MuiSlider-valueLabel"
                                                                aria-hidden="true"
                                                            >
                                                                <span class="MuiSlider-valueLabelCircle">
                                                                    <span class="MuiSlider-valueLabelLabel">7.6</span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px' }}>
                                                        Use sup protection until {weatherData.uvProtectionTill}.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.SunIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                SUNSET
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        {weatherData.sunset}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                        style={{ visibility: 'hidden' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px' }}>Sunrise: {weatherData.sunrise}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.WindIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                WIND
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '90px', transform: 'translateY(-40px)' }}>
                                                        {icons.WindGaugeIcon()}
                                                    </Typography>
                                                    <div className="windSpeedValue">
                                                        <div>{weatherData.windSpeed}</div>
                                                        <div>mph</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.PrecipitationIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                PRECIPITATION
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        <p style={{ marginTop: '0' }}>{weatherData.precipitation}''</p>
                                                        <p style={{ marginTop: '-10px' }}>in last 24 hours</p>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px' }}>
                                                        Next expected is {weatherData.rain}" rain Sun.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.FeelsLikeIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                FEELS LIKE
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        {weatherData.feelsLike}&deg;
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        style={{ visibility: 'hidden' }}
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '11px',
                                                            display: weatherData.feelsLike < weatherData.temperature ? 'block' : 'none'
                                                        }}
                                                    >
                                                        Wind is making it feel cooler.
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '11px',
                                                            display: weatherData.feelsLike > weatherData.temperature ? 'block' : 'none'
                                                        }}
                                                    >
                                                        Feels warmer due to humidity.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '122px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.HumidityIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                HUMIDITY
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        {weatherData.humidity}%
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                        style={{ visibility: 'hidden' }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    style={{
                                                        marginTop: '-10px',
                                                        fontSize: '10px',
                                                        color: '#fff'
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '11px' }}>
                                                        The dew point is {Math.ceil(weatherData.dewPoint)} degree right now
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '61px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            {icons.VisibilityIcon()}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                VISIBILITY
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        {weatherData.visibility}mi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                        style={{ visibility: 'hidden' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px', visibility: 'hidden' }}>
                                                        Wind is making it cooler
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '61px', width: '122px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            <Typography
                                                                style={{
                                                                    fontSize: '30px',
                                                                    lineHeight: '10px',
                                                                    transform: 'translateY(-6px) translateX(-15px)'
                                                                }}
                                                            >
                                                                {icons.PressureIcon()}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                PRESSURE
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}>
                                                        {weatherData.pressure}%
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                        style={{ visibility: 'hidden' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px', visibility: 'hidden' }}>
                                                        The dew point is 56 degree right now
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12}>
                                        <Box sx={{ border: '1px solid #5E667A', borderRadius: '10px', height: '72px', width: '259px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={2.5} sx={{ textAlign: 'left', fontSize: '18px' }}>
                                                            <Typography
                                                                style={{
                                                                    fontSize: '30px',
                                                                    lineHeight: '10px',
                                                                    transform: 'translateY(-6px) translateX(-15px)'
                                                                }}
                                                            >
                                                                {icons.PressureIcon()}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            sx={{ textAlign: 'left', lineHeight: '10px!importanrt', marginTop: '5px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', lineHeight: '10px !importanrt' }}>
                                                                POLLEN
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sm={6}>
                                                        <Typography
                                                            sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}
                                                        >
                                                            Grass: {weatherData.pollen.grass}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <Typography
                                                            sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}
                                                        >
                                                            Trees: {weatherData.pollen.tree}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} style={{ marginTop: '-10px' }}>
                                                        <Typography
                                                            sx={{ fontSize: '14px', lineHeight: '14px !importanrt', color: '#fff' }}
                                                        >
                                                            Weed: {weatherData.pollen.weed}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={10} style={{ marginTop: '-10px' }}>
                                                    <Slider
                                                        disabled
                                                        defaultValue={50}
                                                        aria-label="Default"
                                                        valueLabelDisplay="auto"
                                                        style={{ visibility: 'hidden' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '-10px', fontSize: '10px', color: '#fff' }}>
                                                    <Typography sx={{ fontSize: '11px', visibility: 'hidden' }}>
                                                        The dew point is 56 degree right now
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                    )}
                </Box>
                {/* </SwipeableViews> */}
            </Box>
        </React.Fragment>
    );
};

WeatherTool.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired
};
export default WeatherTool;
