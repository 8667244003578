import Cookies from 'js-cookie';
import Routes from 'routes';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import ProtectedRoute from 'auth/ProtectedRoute';
import TwinupWorldLayout from 'layout/MainLayout';
import Login from 'pages/login';
import { store } from './store';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function TwinworldApp({ scene_id, scene_folder }) {
    return (
        <ProtectedRoute>
            <Provider store={store}>
                <TwinupWorldLayout scene_id={scene_id} scene_folder={scene_folder} />
            </Provider>
        </ProtectedRoute>
    );
}

TwinworldApp.propTypes = {
    scene_id: PropTypes.string,
    scene_folder: PropTypes.string
};

export default TwinworldApp;
