// material-ui
import { Box, Typography } from '@mui/material';
import { Provider } from 'react-redux';
// project import
import AssetGroup from './AssetGroup';
import menuItem from 'menu-items';
import { store } from '../../../../../store';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const AssetLibrary = () => {
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                // return <AssetGroup key={item.id} item={item} />;
                return (
                    <Provider store={store}>
                        <AssetGroup key={item.id} item={item} />
                    </Provider>
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default AssetLibrary;
