import { useRef } from 'react';
import { Box, ButtonBase, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { NotificationFilled, NotificationOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { acknowledgeNotification } from 'store/reducers/user';
import { setIsNotifDrawerOpen } from 'store/reducers/notifications';

const Notifications = () => {
    const notifications = useSelector((state) => state?.user?.obj?.notifications);
    const dispatch = useDispatch();

    const isNotifDrawerOpen = useSelector((state) => state?.notifications);

    const anchorRef = useRef(null);

    return (
        <Box>
            <ButtonBase ref={anchorRef} onClick={() => dispatch(setIsNotifDrawerOpen(true))}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    {notifications?.length ? <NotificationFilled /> : <NotificationOutlined />}
                    {notifications?.length ? <Typography variant="subtitle1">{notifications?.length}</Typography> : ''}
                </Stack>
            </ButtonBase>
            <Drawer anchor="right" open={isNotifDrawerOpen} onClose={() => dispatch(setIsNotifDrawerOpen(false))}>
                <Box
                    sx={{ width: '250px' }}
                    aria-label="notifications"
                    onClick={() => dispatch(setIsNotifDrawerOpen(false))}
                    onKeyDown={() => dispatch(setIsNotifDrawerOpen(false))}
                >
                    <Typography variant="h4">Notifications</Typography>
                    <Divider />
                    <List>
                        {notifications?.map((notif, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        dispatch(acknowledgeNotification({ notificationId: notif?.id }));
                                    }}
                                >
                                    <ListItemText primary={`${notif?.notification_type} from ${notif?.initiator_user?.user_name}`} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

export default Notifications;
