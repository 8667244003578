// assets
import Icon from '@ant-design/icons';

import { ReactComponent as InfoIconSvg } from '../icons/RightToolbar/Info.svg';
import { ReactComponent as TransformIconSvg } from '../icons/RightToolbar/Transform.svg';
import { ReactComponent as MeasurementIconSvg } from '../icons/RightToolbar/Measurement.svg';
import { ReactComponent as ShowHideIconSvg } from '../icons/RightToolbar/ShowHide.svg';
import { ReactComponent as StyleIconSvg } from '../icons/RightToolbar/Style.svg';
import { ReactComponent as ModelingIconSvg } from '../icons/RightToolbar/Modeling.svg';
import { ReactComponent as LinkIconSvg } from '../icons/RightToolbar/Link.svg';
import { ReactComponent as AnnotationIconSvg } from '../icons/RightToolbar/Annotation.svg';
import { ReactComponent as PovIconSvg } from '../icons/RightToolbar/Pov.svg';
import { ReactComponent as RobotIconSvg } from '../icons/RightToolbar/Robot.svg';
import { ReactComponent as AttachIconSvg } from '../icons/RightToolbar/Attach.svg';
import { ReactComponent as ChatIconSvg } from '../icons/RightToolbar/Chat.svg';
import { ReactComponent as LegendIconSvg } from '../icons/RightToolbar/Legend.svg';

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;

// icons
const icons = {
    LegendIcon: (props) => SvgIcon(LegendIconSvg, props),
    InfoIcon: (props) => SvgIcon(InfoIconSvg, props),
    TransformIcon: (props) => SvgIcon(TransformIconSvg, props),
    MeasurementIcon: (props) => SvgIcon(MeasurementIconSvg, props),
    ShowHideIcon: (props) => SvgIcon(ShowHideIconSvg, props),
    StyleIcon: (props) => SvgIcon(StyleIconSvg, props),
    ModelingIcon: (props) => SvgIcon(ModelingIconSvg, props),
    LinkIcon: (props) => SvgIcon(LinkIconSvg, props),
    AnnotationIcon: (props) => SvgIcon(AnnotationIconSvg, props),
    PovIcon: (props) => SvgIcon(PovIconSvg, props),
    RobotIcon: (props) => SvgIcon(RobotIconSvg, props),
    AttachIcon: (props) => SvgIcon(AttachIconSvg, props),
    ChatIcon: (props) => SvgIcon(ChatIconSvg, props)
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const tools = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Info',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.InfoIcon,
            breadcrumbs: false
        },
        {
            id: 'Transform',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.TransformIcon,
            breadcrumbs: false
        },
        {
            type: 'divider',
            id: 'divider1'
        },
        {
            id: 'Measurement',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.MeasurementIcon,
            breadcrumbs: false
        },
        {
            id: 'ShowHide',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.ShowHideIcon,
            breadcrumbs: false
        },
        {
            id: 'Style',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.StyleIcon,
            breadcrumbs: false
        },
        {
            id: 'Modeling',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.ModelingIcon,
            breadcrumbs: false
        },
        {
            type: 'divider',
            id: 'divider2'
        },
        {
            id: 'Link',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.LinkIcon,
            breadcrumbs: false
        },
        {
            id: 'Annotation',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.AnnotationIcon,
            breadcrumbs: false
        },
        {
            id: 'Pov',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.PovIcon,
            breadcrumbs: false
        },
        {
            id: 'Robot',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.RobotIcon,
            breadcrumbs: false
        },
        {
            id: 'Attach',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.AttachIcon,
            breadcrumbs: false
        },
        {
            type: 'divider',
            id: 'divider3'
        },
        {
            id: 'Chat',
            title: '',
            type: 'item',
            url: '/',
            icon: icons.ChatIcon,
            breadcrumbs: false
        }
    ]
};

export default tools;
