import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, IconButton, Toolbar } from '@mui/material';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import FilterDrawer from './FilterDrawer';
import Tools from './Tools';
import tools from 'menu-items/tools';

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const FiltersLayout = () => {
    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // const auth = useSelector((state) => state.auth.value);
    const [open, setOpen] = React.useState(false);
    const [activeTool, setActiveTool] = React.useState('');

    const [navOpen, setNavOpen] = useState(true);

    const dispatch = useDispatch();

    function openNav() {
        setNavOpen(true);
    }

    function closeNav() {
        setNavOpen(false);
    }

    function handleNavToggle() {
        if (navOpen) {
            closeNav();
        } else {
            openNav();
        }
    }

    function closeInfo() {
        setOpen(false);
        setActiveTool('');
    }

    return (
        <React.Fragment>
            <Tools
                id="filterTools"
                tools={tools}
                open={open}
                toggleOpen={() => {
                    // open ? setOpen(false) : setOpen(true);
                }}
                setActiveTool={(newTool) => {
                    console.log(newTool, activeTool);
                    if (newTool !== activeTool) {
                        setOpen(true);
                        setActiveTool(newTool);
                    } else {
                        setOpen(false);
                        setActiveTool('');
                    }
                    if (newTool === 'Info') {
                        setTimeout(() => {
                            setOpen(true);
                            setActiveTool(newTool);
                        }, 2);
                    }
                    /*if (newTool === 'Robot') {
                        setTimeout(() => {
                            if (window.scanState === 'scanning') {
                                setOpen(false);
                            } else if (window.scanState === 'complete') {
                                setOpen(true);
                            }
                        }, 3000);
                    }*/
                }}
            />
            <FilterDrawer filterToolsId="filterTools" open={open} activeTool={activeTool} closeInfo={closeInfo} />
        </React.Fragment>
    );
};

export default FiltersLayout;
