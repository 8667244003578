import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import visibleImage from 'icons/RightToolbar/Visible.svg';
import Switcher from 'components/Switcher';
import Choice from 'components/Choice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const AnnotationTool = ({ assetInfo, closeSurvey }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <React.Fragment>
            {window.pickedAssetDetails && (
                <Box sx={{ padding: '15px' }}>
                    <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: '#fff', color: '#fff' } }}
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="point of view tabs"
                        >
                            <Tab
                                sx={{
                                    color: '#fff',
                                    fontFamily: 'Suisse Intl',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                                label="Selection"
                                {...a11yProps(0)}
                            />
                            <Tab
                                sx={{
                                    color: '#fff',
                                    fontFamily: 'Suisse Intl',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                                label="Saved Views"
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <div>
                                    <div className="filterToolLabel">Display Title</div>
                                    <TextField className="filterToolText" disabled value={'View Annotation'} />
                                </div>
                                <Divider sx={{ borderBottom: '2px solid rgba(0,0,0,0.5)' }} />
                                <div>
                                    <div className="filterToolLabel">HOTSPOT</div>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <FormLabel>Color Style</FormLabel>
                                                    <Select
                                                        className="filterToolText"
                                                        disabled
                                                        value={'formatColor'}
                                                        label="Color Style"
                                                        sx={{
                                                            height: '42px',
                                                            marginTop: '5px',
                                                            marginRight: '0px',
                                                            width: '90%',
                                                            color: '#000 !important',
                                                            backgroundColor: '#f3bb06'
                                                        }}
                                                    >
                                                        <MenuItem value={'formatColor'}>
                                                            <FormatColorTextIcon />
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <FormLabel>Size</FormLabel>
                                                    <br />
                                                    <Select
                                                        className="filterToolText"
                                                        disabled
                                                        value={'mid'}
                                                        label="Size"
                                                        sx={{
                                                            height: '42px',
                                                            marginTop: '5px',
                                                            marginRight: '20px',
                                                            width: '90%'
                                                        }}
                                                    >
                                                        <MenuItem value={'mid'}>Mid</MenuItem>
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Choice alignment="column" label="Position" choiceItems={['2D', '3D']} defaultChoice="3D" />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                                <Divider sx={{ borderBottom: '1px solid rgba(0,0,0,0.5)' }} />
                                <div>
                                    <div className="filterToolLabel">Visibility</div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={1}>
                                            <div className="clickedPointLabel">
                                                <img src={visibleImage} alt="Visible entity" width="20" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="clickedPointText">Visible</div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Stack>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            Item Two
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            )}
            {!window.pickedAssetDetails && (
                <Box sx={{ padding: '15px' }}>
                    <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: '#fff', color: '#fff' } }}
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="point of view tabs"
                        >
                            <Tab
                                sx={{
                                    color: '#fff',
                                    fontFamily: 'Suisse Intl',
                                    fontSize: '14px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                                label="Selection"
                                {...a11yProps(0)}
                            />
                            <Tab
                                sx={{
                                    color: '#fff',
                                    fontFamily: 'Suisse Intl',
                                    fontSize: '14px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                                label="All Annotations"
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <div style={{ fontSize: '16px' }}>Nothing selected.</div>
                            <div style={{ fontSize: '16px' }}>
                                Click on a 3D model to see the view from that spot, or pick an existing point-of-view hotspot.
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            Item Two
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            )}
        </React.Fragment>
    );
};

AnnotationTool.propTypes = {
    assetInfo: PropTypes.object,
    closeSurvey: PropTypes.func
};
export default AnnotationTool;
