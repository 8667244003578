import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import visibleImage from 'icons/RightToolbar/Visible.svg';
import Switcher from 'components/Switcher';
import Choice from 'components/Choice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

TabPanel.propTypes = {
    children: PropTypes.string,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: grey[800],
    '&:not(:last-of-type)': {
        borderColor: '#fff !important'
    },
    '&:hover': {
        backgroundColor: grey[700]
    }
}));

const RobotTool = ({ closeRobot }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [scanningState, setScanningState] = React.useState('scanning');

    //const [backdropOpen, setBackdropOpen] = React.useState(!window.scanningTraceInterval || window.scanState === 'scanning');
    const [backdropOpen, setBackdropOpen] = React.useState(true);
    const handleClose = () => {
        clearInterval(window.scanningTraceInterval);
        window.scanningTraceInterval = null;
        setBackdropOpen(false);
    };
    const handleToggle = () => {
        setBackdropOpen(!backdropOpen);
    };

    React.useEffect(() => {
        if (window.scanState === 'complete') {
            setBackdropOpen(false);
            setScanningState('scanComplete');
        } else {
            window.startRobotScanner(200, true, false);
            setScanningState('scanning');
        }
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <h1 style={{ fontSize: '24px' }}>Location Intelligence</h1>
                {scanningState === 'scanning' && (
                    <div>
                        {/* <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backdropOpen}
                            onClick={handleClose}
                        >
                            <Typography variant="h6" sx={{ textDecoration: 'none', color: '#fff' }} color="textSecondary">
                                <h3>Scanning surrounding... &nbsp;</h3>
                            </Typography>
                            <CircularProgress color="inherit" />
                        </Backdrop> */}
                        {/* <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            TransitionComponent={SlideTransition}
                            open={backdropOpen}
                            onClose={handleClose}
                            message="Scanning surrounding... "
                            key={'progress'}
                        /> */}
                        <h2>Scanning surrounding...</h2>
                        <CircularProgress color="inherit" />
                    </div>
                )}
                {scanningState === 'scanComplete' && (
                    <Box sx={{ padding: '15px' }}>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}>
                            <h2>Location Intelligence scanner complete at user level</h2>
                        </AppBar>
                        <Fab
                            aria-label="close"
                            onClick={handleClose}
                            color="gray"
                            className="surveyClose"
                            sx={{
                                border: 'none !important',
                                boxShadow: 'none'
                            }}
                        >
                            <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                        </Fab>
                        <h3>List of surrounding entities :</h3>
                        {scanningState === 'scanComplete' &&
                            window.entityNames &&
                            window.entityNames.map((entityName, index) => {
                                return (
                                    <div key={`${entityName}_${index}`}>
                                        {index + 1}
                                        {`) `}
                                        {entityName}
                                    </div>
                                );
                            })}
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

RobotTool.propTypes = {
    closeRobot: PropTypes.func
};
export default RobotTool;
