import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser, updateUserImages } from 'store/reducers/user';
import InputRow from 'components/InputRow';
import PropTypes from 'prop-types';

const UserDialog = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state?.user?.obj);
    const profileImage = user?.profile_image?.s3_url;

    const dialogContentText = '';

    const initialValues = {
        name: user?.name,
        title: user?.title,
        location: user?.location,
        company: user?.company,
        website: user?.website,
        email: user?.email,
        bio: user?.bio
    };

    const userSchema = Yup.object().shape({
        email: Yup.string().email().required('Email is required')
    });

    const InputProps = { accept: 'image/*', type: 'file', style: {} };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={(values) => {
                dispatch(updateUser(values));
                onClose();
            }}
            enableReinitialize
        >
            {(formik) => {
                const { isValid, dirty, resetForm, submitForm } = formik;
                return (
                    <Dialog
                        fullWidth
                        onClose={() => {
                            onClose();
                            resetForm();
                        }}
                        open={open}
                    >
                        <DialogTitle>Update Profile</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{dialogContentText}</DialogContentText>
                            <Avatar alt="profile user" src={profileImage} sx={{ width: 75, height: 75 }} />
                            <label htmlFor="file">Profile Photo: </label>
                            <input
                                {...InputProps}
                                id="profileImage"
                                onChange={(e) => dispatch(updateUserImages({ type: 'profile', data: e.currentTarget.files[0] }))}
                            />
                            <InputRow field="name" />
                            <InputRow field="title" />
                            <InputRow field="company" />
                            <InputRow field="email" />
                            <InputRow field="bio" />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    onClose();
                                    resetForm();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" disabled={!isValid || !dirty} onClick={submitForm}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            }}
        </Formik>
    );
};

UserDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default UserDialog;
