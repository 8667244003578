import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar as MuiAvatar, Box } from '@mui/material';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
// import { useAppSelector } from 'hooks';
import { useSelector } from 'react-redux';

const Avatar = (props) => {
    const [user, setUser] = React.useState({});
    React.useEffect(() => {
        fetch('https://dev.thetwinmaster.com/api/v1/user/')
            .then((res) => res.json())
            .then((resJson) => setUser(resJson));
    }, []);
    const { size, fontSize, disableNavigate } = props;
    const me = useSelector((state) => {
        if (state.user && state.user.me) {
            return state.user.me;
        }
    });
    const navigate = useNavigate();
    return (
        <Box
            onClick={() => {
                if (user && me && !disableNavigate) {
                    window.location.pathname = user.id === me.id ? '/profile' : `/profile/${user.id}`;
                }
            }}
            sx={{ cursor: disableNavigate ? 'inherit' : 'pointer' }}
        >
            {user && user.profile_image && (
                <MuiAvatar alt="profile user" src={user.profile_image.s3_url} sx={{ width: size, height: size }} />
            )}
            {user && user.profile_image === null && (
                <MuiAvatar
                    sx={{ width: size, height: size, bgcolor: '#FEF1ED', color: '#F36F4B', fontSize: fontSize ? fontSize : 'inherit' }}
                >
                    {user.name ? user.name.slice(0, 1).toUpperCase() : user.user_name.slice(0, 1).toUpperCase()}
                </MuiAvatar>
            )}
            {user === null && (
                <MuiAvatar sx={{ width: size, height: size, bgcolor: '#FEF1ED' }}>
                    <Bars height={fontSize ? fontSize : 16} width="100%" color="#F36F4B" ariaLabel="bars-loading" visible={true} />
                </MuiAvatar>
            )}
        </Box>
    );
};

Avatar.propTypes = {
    user: PropTypes.object,
    size: PropTypes.number,
    fontSize: PropTypes.number,
    disableNavigate: PropTypes.bool
};

export default Avatar;
